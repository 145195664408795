const sliderLeftArrow = require("url:../../assets/icons/left-arrow.svg");
const sliderRightArrow = require("url:../../assets/icons/right-arrow.svg");

const indaloMediaLogo = require("url:../../assets/indalo-media.svg");
const oilLogo = require("url:../../assets/oil-combustibles.svg");
const cpcLogo = require("url:../../assets/cpc-logo.svg");
const providenciaLogo = require("url:../../assets/providencia-logo.svg");
const alpatLogo = require("url:../../assets/alpat-logo.svg");
const sermaLogo = require("url:../../assets/serma-logo.svg");
const pilgrimEnergyLogo = require("url:../../assets/pilgrim-energy.svg");

class CompaniesSliderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		const style = document.createElement("style");

		style.textContent = `
        
        .carousel-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 45px;
        }

        .carousel {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
        }

        @media (min-width: 630px) {
            .carousel {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
            }
        }

        .sliders-container {
            width: calc(100% + 13% * 5);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6%;
            transform: translateX(-40%);
            transition: all .7s ease;
			scroll-snap-type: x mandatory;
			scroll-behavior: smooth;
        }

        @media (min-width: 425px) {
            .sliders-container {
                gap: 10%;
            }
        }

		@media (min-width: 500px) {
			.sliders-container {
				transform: translateX(-37%);
			}
		}

        @media (min-width: 630px) {
            .sliders-container {
                transform: translateX(-15%);
            }
        }

        .slider__item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            opacity: .35;
            transform: scale(0.9);
            transition: all .6s;
        }

        .slider__item.active {
            opacity: 1;
            transform: scale(1);            
        }

        .item__controlls {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 45px;
            mix-blend-mode: difference;
        }

        .left-arrow, 
        .right-arrow {
            opacity: 0.7;
        }

        .left-arrow:hover, 
        .right-arrow:hover {
            cursor: pointer;
            opacity: 1;
        }

        `;

		this.render();

		this.shadow.appendChild(style);
	}

	addListeners() {
		const carouselEl: HTMLElement = this.shadow.querySelector(".carousel");

		const SlContainerEl: HTMLElement =
			this.shadow.querySelector(".sliders-container");

		const slidersItemsEl = this.shadow.querySelectorAll(".slider__item");
		const leftArrowEl: HTMLElement =
			this.shadow.querySelector(".left-arrow");
		const rightArrowEl: HTMLElement =
			this.shadow.querySelector(".right-arrow");

		let isDragging = false,
			startPos = 0,
			currentTranslate = 0,
			prevTranslate = 0,
			animationID,
			currentIndex = 0;

		slidersItemsEl.forEach((slide, index) => {
			slide.addEventListener("touchstart", touchStart(index));
			slide.addEventListener("mousedown", touchStart(index));

			slide.addEventListener("touchend", touchEnd);
			slide.addEventListener("mouseup", touchEnd);

			slide.addEventListener("touchmove", touchMove);
			slide.addEventListener("mousemove", touchMove);
		});

		function getPositionX(event) {
			return event.type.includes("mouse")
				? event.pageX
				: event.touches[0].clientX;
		}

		function touchStart(index) {
			return function (event) {
				currentIndex = index;
				startPos = getPositionX(event);
				isDragging = true;
				animationID = requestAnimationFrame(animation);
			};
		}

		function touchMove(event) {
			if (isDragging) {
				const currentPosition = getPositionX(event);
				currentTranslate = prevTranslate + currentPosition - startPos;
			}
		}

		function touchEnd() {
			cancelAnimationFrame(animationID);
			isDragging = false;

			const offset = SlContainerEl.getAttribute("offset");
			const movedBy = currentTranslate - prevTranslate;

			// if moved enough negative then snap to next slide if there is one
			if (window.innerWidth <= 425) {
				if (movedBy > 100 && offset == "0") {
					SlContainerEl.style.transform = "translateX(-120%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (movedBy > 100 && offset == "1") {
					SlContainerEl.style.transform = "translateX(80%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (movedBy > 100 && offset == "2") {
					SlContainerEl.style.transform = "translateX(40%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (movedBy > 100 && offset == "3") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (movedBy > 100 && offset == "4") {
					SlContainerEl.style.transform = "translateX(-40%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (movedBy > 100 && offset == "5") {
					SlContainerEl.style.transform = "translateX(-80%)";
					SlContainerEl.setAttribute("offset", "4");
				}
			} else if (window.innerWidth >= 450 && window.innerWidth < 600) {
				if (movedBy > 100 && offset == "0") {
					SlContainerEl.style.transform = "translateX(-108%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (movedBy > 100 && offset == "1") {
					SlContainerEl.style.transform = "translateX(69%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (movedBy > 100 && offset == "2") {
					SlContainerEl.style.transform = "translateX(34%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (movedBy > 100 && offset == "3") {
					SlContainerEl.style.transform = "translateX(-2%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (movedBy > 100 && offset == "4") {
					SlContainerEl.style.transform = "translateX(-37%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (movedBy > 100 && offset == "5") {
					SlContainerEl.style.transform = "translateX(-73%)";
					SlContainerEl.setAttribute("offset", "4");
				}
			} else if (window.innerWidth >= 630) {
				if (movedBy > 100 && offset == "0") {
					SlContainerEl.style.transform = "translateX(-75%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (movedBy > 100 && offset == "1") {
					SlContainerEl.style.transform = "translateX(75%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (movedBy > 100 && offset == "2") {
					SlContainerEl.style.transform = "translateX(45%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (movedBy > 100 && offset == "3") {
					SlContainerEl.style.transform = "translateX(15%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (movedBy > 100 && offset == "4") {
					SlContainerEl.style.transform = "translateX(-15%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (movedBy > 100 && offset == "5") {
					SlContainerEl.style.transform = "translateX(-45%)";
					SlContainerEl.setAttribute("offset", "4");
				}
			}

			if (window.innerWidth <= 425) {
				if (movedBy < -100 && offset == "0") {
					SlContainerEl.style.transform = "translateX(40%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (movedBy < -100 && offset == "1") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (movedBy < -100 && offset == "2") {
					SlContainerEl.style.transform = "translateX(-40%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (movedBy < -100 && offset == "3") {
					SlContainerEl.style.transform = "translateX(-80%)";
					SlContainerEl.setAttribute("offset", "4");
				} else if (movedBy < -100 && offset == "4") {
					SlContainerEl.style.transform = "translateX(-120%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (movedBy < -100 && offset == "5") {
					SlContainerEl.style.transform = "translateX(80%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			} else if (window.innerWidth >= 450 && window.innerWidth < 600) {
				if (movedBy < -100 && offset == "0") {
					SlContainerEl.style.transform = "translateX(34%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (movedBy < -100 && offset == "1") {
					SlContainerEl.style.transform = "translateX(-2%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (movedBy < -100 && offset == "2") {
					SlContainerEl.style.transform = "translateX(-37%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (movedBy < -100 && offset == "3") {
					SlContainerEl.style.transform = "translateX(-73%)";
					SlContainerEl.setAttribute("offset", "4");
				} else if (movedBy < -100 && offset == "4") {
					SlContainerEl.style.transform = "translateX(-108%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (movedBy < -100 && offset == "5") {
					SlContainerEl.style.transform = "translateX(69%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			} else if (window.innerWidth >= 630) {
				if (movedBy < -100 && offset == "0") {
					SlContainerEl.style.transform = "translateX(45%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (movedBy < -100 && offset == "1") {
					SlContainerEl.style.transform = "translateX(15%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (movedBy < -100 && offset == "2") {
					SlContainerEl.style.transform = "translateX(-15%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (movedBy < -100 && offset == "3") {
					SlContainerEl.style.transform = "translateX(-45%)";
					SlContainerEl.setAttribute("offset", "4");
				} else if (movedBy < -100 && offset == "4") {
					SlContainerEl.style.transform = "translateX(-75%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (movedBy < -100 && offset == "5") {
					SlContainerEl.style.transform = "translateX(75%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			}
		}

		function animation() {
			if (isDragging) requestAnimationFrame(animation);
		}

		const leftArrowListener = (e) => {
			const target = e.target as HTMLElement;
			const position = target.getAttribute("position");
			const offset = SlContainerEl.getAttribute("offset");

			if (window.innerWidth <= 425) {
				if (position == "previous" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-120%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (position == "previous" && offset == "1") {
					SlContainerEl.style.transform = "translateX(80%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (position == "previous" && offset == "2") {
					SlContainerEl.style.transform = "translateX(40%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "previous" && offset == "3") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "previous" && offset == "4") {
					SlContainerEl.style.transform = "translateX(-40%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (position == "previous" && offset == "5") {
					SlContainerEl.style.transform = "translateX(-80%)";
					SlContainerEl.setAttribute("offset", "4");
				}
			} else if (window.innerWidth >= 450 && window.innerWidth < 600) {
				if (position == "previous" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-108%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (position == "previous" && offset == "1") {
					SlContainerEl.style.transform = "translateX(69%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (position == "previous" && offset == "2") {
					SlContainerEl.style.transform = "translateX(34%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "previous" && offset == "3") {
					SlContainerEl.style.transform = "translateX(-2%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "previous" && offset == "4") {
					SlContainerEl.style.transform = "translateX(-37%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (position == "previous" && offset == "5") {
					SlContainerEl.style.transform = "translateX(-73%)";
					SlContainerEl.setAttribute("offset", "4");
				}
			} else if (window.innerWidth >= 630) {
				if (position == "previous" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-75%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (position == "previous" && offset == "1") {
					SlContainerEl.style.transform = "translateX(75%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (position == "previous" && offset == "2") {
					SlContainerEl.style.transform = "translateX(45%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "previous" && offset == "3") {
					SlContainerEl.style.transform = "translateX(15%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "previous" && offset == "4") {
					SlContainerEl.style.transform = "translateX(-15%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (position == "previous" && offset == "5") {
					SlContainerEl.style.transform = "translateX(-45%)";
					SlContainerEl.setAttribute("offset", "4");
				}
			}
		};

		const rightArrowListener = (e) => {
			const target = e.target as HTMLElement;
			const position = target.getAttribute("position");
			const offset = SlContainerEl.getAttribute("offset");

			if (window.innerWidth <= 425) {
				if (position == "next" && offset == "0") {
					SlContainerEl.style.transform = "translateX(40%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "next" && offset == "1") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "next" && offset == "2") {
					SlContainerEl.style.transform = "translateX(-40%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (position == "next" && offset == "3") {
					SlContainerEl.style.transform = "translateX(-80%)";
					SlContainerEl.setAttribute("offset", "4");
				} else if (position == "next" && offset == "4") {
					SlContainerEl.style.transform = "translateX(-120%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (position == "next" && offset == "5") {
					SlContainerEl.style.transform = "translateX(80%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			} else if (window.innerWidth >= 450 && window.innerWidth < 600) {
				if (position == "next" && offset == "0") {
					SlContainerEl.style.transform = "translateX(34%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "next" && offset == "1") {
					SlContainerEl.style.transform = "translateX(-2%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "next" && offset == "2") {
					SlContainerEl.style.transform = "translateX(-37%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (position == "next" && offset == "3") {
					SlContainerEl.style.transform = "translateX(-73%)";
					SlContainerEl.setAttribute("offset", "4");
				} else if (position == "next" && offset == "4") {
					SlContainerEl.style.transform = "translateX(-108%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (position == "next" && offset == "5") {
					SlContainerEl.style.transform = "translateX(69%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			} else if (window.innerWidth >= 630) {
				if (position == "next" && offset == "0") {
					SlContainerEl.style.transform = "translateX(45%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "next" && offset == "1") {
					SlContainerEl.style.transform = "translateX(15%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "next" && offset == "2") {
					SlContainerEl.style.transform = "translateX(-15%)";
					SlContainerEl.setAttribute("offset", "3");
				} else if (position == "next" && offset == "3") {
					SlContainerEl.style.transform = "translateX(-45%)";
					SlContainerEl.setAttribute("offset", "4");
				} else if (position == "next" && offset == "4") {
					SlContainerEl.style.transform = "translateX(-75%)";
					SlContainerEl.setAttribute("offset", "5");
				} else if (position == "next" && offset == "5") {
					SlContainerEl.style.transform = "translateX(75%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			}
		};

		leftArrowEl.addEventListener("click", leftArrowListener);
		rightArrowEl.addEventListener("click", rightArrowListener);

		var observer = new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				if (mutation.type === "attributes") {
					slidersItemsEl.forEach((item) => {
						const itemOffset = item.getAttribute("offset");
						const sliderOffset =
							SlContainerEl.getAttribute("offset");
						if (sliderOffset == itemOffset) {
							item.classList.add("active");
						} else {
							item.classList.remove("active");
						}
					});
				}
			});
		});

		observer.observe(SlContainerEl, {
			attributes: true,
		});

		const translationOnResize = () => {
			leftArrowEl.removeEventListener("click", leftArrowListener);
			leftArrowEl.removeEventListener("click", rightArrowListener);

			leftArrowEl.addEventListener("click", leftArrowListener);
			rightArrowEl.addEventListener("click", rightArrowListener);
		};

		window.addEventListener("resize", translationOnResize);
	}

	render() {
		this.shadow.innerHTML = `
        <div class="carousel-container">
            <div class="carousel">
                <div class="sliders-container" offset="3">
                    <div class="slider__item" offset="0">
                        <company-card path="/company/indalo-media" src="${indaloMediaLogo}">
							Es uno de los principales multimedios de la Argentina. Tiene una fuerte presencia en la industria de la televisión, la radio, los medios gráficos y digitales y en la producción de contenidos audiovisuales.
                        </company-card>
                    </div>
                    <div class="slider__item" offset="1">
                        <company-card path="/company/oil-m&s" src="${oilLogo}">
							Compañía proveedora de soluciones integrales a la industria hidrocarburífera y minera en todo el país.
                        </company-card>
                    </div>
                    <div class="slider__item" offset="2">
                        <company-card path="/company/cpc" src="${cpcLogo}">
                            Empresa líder en construcción de obras públicas de gran envergadura.
                        </company-card>
                    </div>
                    <div class="slider__item active" offset="3">
                        <company-card path="/company/providencia" src="${providenciaLogo}">
							Aseguradora enfocada en brindar un amplio espectro de servicios a personas y empresas a través de brokers y productores.
                        </company-card>
                    </div>
                    <div class="slider__item" offset="4">
                        <company-card path="/company/alpat" src="${alpatLogo}">
							Única empresa productora de carbonato de sodio de Latinoamérica.
                        </company-card>
                    </div>
                    <div class="slider__item" offset="5">
                        <company-card path="/company/serma" src="${sermaLogo}">
							Brinda servicios de ingeniería ambiental a empresas de la industria hidrocarburífera y minera.
                        </company-card>
                    </div>
                </div>
            </div>
            <div class="item__controlls">
            <img class="chevron-arrow left-arrow" src="${sliderLeftArrow}" position="previous">
            <img class="chevron-arrow right-arrow" src="${sliderRightArrow}" position="next">
            </div>
        </div>
        `;
		this.addListeners();
	}
}
customElements.define("companies-slider", CompaniesSliderComponent);
