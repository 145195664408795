class AboutComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
            .about-container {
                height: 90vh;
                width: 100vw;
                display: flex;
                justify-content: space-between;
                align-items: center;

            }

            .about__side-bar {
                width: 40%;
                height: calc(100% - 25px);
                padding: 0 25px;
                padding-top: 25px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                border-right: 1px solid var(--white-color);
                border-top: 1px solid var(--white-color);
            }

            @media (min-width: 768px) {
                .about__side-bar {
                    height: calc(100% - 50px);
                    padding: 0 50px;
                    padding-top: 50px;
                }
            }

            @media (min-width: 960px) {
                .about__side-bar {
                    width: 38%;
                }
            }

            @media (min-width: 1280px) {
                .about__side-bar {
                    width: 30%;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .about__side-bar {
                    height: calc(100% - 25px);
                    padding-top: 25px;
                }
            }


            @media (min-width: 1440px) {
                .about__side-bar {
                    width: 42%;
                    height: calc(100% - 50px);
                    padding: 0 100px;
                    padding-top: 50px;
                }
            }

            @media (min-width: 1850px) {
                .about__side-bar {
                    width: 39%;
                    height: calc(100% - 100px);
                    padding-top: 100px;
                }
            }

            .side-bar__faq {
                width: 100%;
            }

            .side-bar__title {
                width: 100%;
                margin: 10px 0;
                font: 700 19px var(--jakarta-font);
                color: var(--white-color);
                text-align: left;
                opacity: .25;
                transition: all .4s;
            }
            
            .side-bar__title:hover {
                cursor: pointer;
            }

            @media (min-width: 960px) {
                .side-bar__title {
                    width: 100%;
                    margin: 10px 0;
                    font-size: 24px;
                }
            }

            @media (min-width: 1280px) {
                .side-bar__title {
                    margin: 27px 0;
                    font-size: 30px;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .side-bar__title {
                    font-size: 25px;
                }
            }

            @media (min-width: 1440px) {
                .side-bar__title {
                    margin: 27px 0;
                    font-size: 32px;
                }
            }

            @media (min-width: 1850px) {
                .side-bar__title {
                    margin: 27px 0;
                    font-size: 47px;
                }
            }

            .side-bar__title.active {
                opacity: 1;
            }

            .side-bar__title.active.yellow {
                color: var(--yellow-color);
            }

            .side-bar__title.active.red {
                color: var(--red-color);
            }

            .side-bar__title.active.blue {
                color: var(--blue-color);
            }

            .side-bar__title.active.green {
                color: var(--green-color);
            }

            .side-bar__content {
                width: 100%;
            }

            @media (min-width: 1850px) {
                .side-bar__content {
                    margin-top: 55px; 
                }
            }

            .content__title {
                font: 700 22px var(--jakarta-font);
                color: var(--white-color);
            }

            @media (min-width: 960px) {
                .content__title {
                    font-size: 30px;
                }
            }

            @media (min-width: 1280px) {
                .content__title {
                    font-size: 33px;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .content__title {
                    font-size: 27px;
                }
            }


            @media (min-width: 1440px) {
                .content__title {
                    font-size: 35px;
                }
            }

            @media (min-width: 1850px) {
                .content__title {
                    margin: 0;
                    font-size: 54px;
                }
            }

            .content__text {
                width: 85%;
                font: 500 12px var(--jakarta-font); 
                color: var(--white-color);
            }

            @media (min-width: 960px) {
                .content__text {
                    font-size: 14px;
                }
            }

            @media (min-width: 1280px) {
                .content__text {
                    font-size: 16px;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .content__text {
                    font-size: 14px;
                }
            }


            @media (min-width: 1440px) {
                .content__text {
                    font-size: 18px;
                }
            }

            @media (min-width: 1850px) {
                .content__text {
                    font-size: 24px;
                }
            }

            .about__content {
                width: 55%;
                height: 100%;
                padding: 0 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top: 1px solid var(--white-color);
            }

            @media (min-width: 768px) {
                .about__content {
                    height: 100%;
                    padding: 0 50px;
                }
            }

            @media (min-width: 960px) {
                .about__content {
                    width: 60%;
                }
            }

            @media (min-width: 1440px) {
                .about__content {
                    padding: 0 100px;
                }
            }

            @media (min-width: 1850px) {
                .about__content {
                    height: 100%;
                }
            }

            .content-item {
                max-width: 300px;
                position: absolute;
                opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
            }
            
            @media (min-width: 768px) {
                .content-item {
                    max-width: 325px;
                }
            }

            @media (min-width: 960px) {
                .content-item {
                    max-width: 450px;
                }
            }

            @media (min-width: 1280px) {
                .content-item {
                    max-width: 670px;
                }
            }

            @media (min-width: 1440px) {
                .content-item {
                    max-width: 600px;
                }
            }

            @media (min-width: 1850px) {
                .content-item {
                    max-width: 840px;
                }
            }

            .content-item.active {
                opacity: 1;
                filter: blur(0);
				transform: translateY(0);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
            <div class="about-container">
                <div class="about__side-bar">
                    <div class="side-bar__faq">
                        <h3 class="side-bar__title yellow active" item="first">¿Qué es el Grupo Indalo?</h3>
                        <h3 class="side-bar__title red" item="second">Misión</h3>
                        <h3 class="side-bar__title blue" item="third">Visión</h3>
                        <h3 class="side-bar__title green" item="fourth">Valores</h3>
                    </div>
                    <div class="side-bar__content">
                        <h3 class="content__title">
                            Nuestro futuro, 
                            <br>
                            mucho por hacer.
                        </h3>
                        <p class="content__text">
                            Fue creado en 1999 y desde entonces se convirtió en uno de los grupos más importantes de la Argentina con proyecciones de crecimiento en toda la región.
                        </p>
                    </div>
                </div>
                <div class="about__content">
                    <div class="content-item first active">
                        <text-comp variant="about" scheme="f-light">
                            El Grupo Indalo es un conglomerado de empresas que invierte en la Argentina desde hace más de veinte años y que apuesta por el desarrollo del país generando empleo para miles de trabajadores y trabajadoras a lo largo de todo el territorio nacional.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                            Está integrado por empresas de producción y servicios de diferentes rubros, principalmente en medios de comunicación, construcción, energía y minería.
                        </text-comp>
                    </div>
                    <div class="content-item second">
                        <text-comp variant="about" scheme="f-light">
                            Nuestra misión es contribuir al desarrollo económico y social de la Argentina a través de la inversión en empresas de diversos sectores, generando empleo y mejorando la calidad de vida de la población.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                            Buscamos ser líderes en cada una de nuestras áreas de negocio, brindando productos y servicios de alta calidad que satisfagan las necesidades de nuestros clientes y generen un impacto positivo en la sociedad.
                        </text-comp>
                    </div>
                    <div class="content-item third">
                        <text-comp variant="about" scheme="f-light">
                            Apuntamos a consolidar el liderazgo de nuestro grupo empresarial en el país y proyectarnos hacia la región en busca de nuevos mercados estratégicos.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                            Ser referentes en cada uno de los sectores en los que participamos, brindando productos y soluciones innovadoras que generen valor a nuestros clientes y a la sociedad en general.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                            Además, nos comprometemos a ser una empresa socialmente responsable, promoviendo la sustentabilidad y el cuidado del medio ambiente en cada una de nuestras operaciones y contribuyendo al desarrollo de las comunidades en las que operamos.
                        </text-comp>
                    </div>
                    <div class="content-item fourth">
                        <text-comp variant="about" scheme="f-light" contains-html>
                            <b>Compromiso:</b>
                            Nos comprometemos a brindar productos innovadores y de calidad a nuestros clientes y a ser un grupo de empresas socialmente responsable que contribuya al desarrollo sostenible de las comunidades en las que opera.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light" contains-html>
                            <b>Innovación:</b>
                            Nos esforzamos por estar a la vanguardia de cada uno de los mercados en los que participamos, invirtiendo en nuevas tecnologías y fomentando la creatividad y la innovación en todos nuestros equipos de trabajo.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light" contains-html>
                            <b>Excelencia:</b>
                            Buscamos la excelencia en todo lo que hacemos, desde la gestión empresarial hasta en la calidad de nuestros productos y servicios.
                        </text-comp>
                    </div>
                </div>
            </div>
        `;
	}

	addListeners() {
		const sideBarTitles = this.shadow.querySelectorAll(".side-bar__title");

		const firstTitleEl = this.shadow.querySelector("[item=first]");
		const secondTitleEl = this.shadow.querySelector("[item=second]");
		const thirdTitleEl = this.shadow.querySelector("[item=third]");
		const fourthTitleEl = this.shadow.querySelector("[item=fourth]");

		const firstContentEl = this.shadow.querySelector(".content-item.first");
		const secondContentEl = this.shadow.querySelector(
			".content-item.second"
		);
		const thirdContentEl = this.shadow.querySelector(".content-item.third");
		const fourthContentEl = this.shadow.querySelector(
			".content-item.fourth"
		);

		sideBarTitles.forEach((title) => {
			title.addEventListener("click", () => {
				const itemAttr = title.getAttribute("item");

				if (itemAttr == "first") {
					firstTitleEl.classList.add("active");
					firstContentEl.classList.add("active");

					secondTitleEl.classList.remove("active");
					thirdTitleEl.classList.remove("active");
					fourthTitleEl.classList.remove("active");

					secondContentEl.classList.remove("active");
					thirdContentEl.classList.remove("active");
					fourthContentEl.classList.remove("active");
				} else if (itemAttr == "second") {
					secondTitleEl.classList.add("active");
					secondContentEl.classList.add("active");

					firstTitleEl.classList.remove("active");
					thirdTitleEl.classList.remove("active");
					fourthTitleEl.classList.remove("active");

					firstContentEl.classList.remove("active");
					thirdContentEl.classList.remove("active");
					fourthContentEl.classList.remove("active");
				} else if (itemAttr == "third") {
					thirdTitleEl.classList.add("active");
					thirdContentEl.classList.add("active");

					firstTitleEl.classList.remove("active");
					secondTitleEl.classList.remove("active");
					fourthTitleEl.classList.remove("active");

					firstContentEl.classList.remove("active");
					secondContentEl.classList.remove("active");
					fourthContentEl.classList.remove("active");
				} else if (itemAttr == "fourth") {
					fourthTitleEl.classList.add("active");
					fourthContentEl.classList.add("active");

					firstTitleEl.classList.remove("active");
					secondTitleEl.classList.remove("active");
					thirdTitleEl.classList.remove("active");

					firstContentEl.classList.remove("active");
					secondContentEl.classList.remove("active");
					thirdContentEl.classList.remove("active");
				}
			});
		});
	}
}

customElements.define("about-content", AboutComponent);
