const en_indaloMediaLogoComp = require("url:../../../assets/indalo-media.svg");
const en_oilLogoComp = require("url:../../../assets/oil-combustibles.svg");
const en_cpcLogoComp = require("url:../../../assets/cpc-logo.svg");
const en_providenciaLogoComp = require("url:../../../assets/providencia-logo.svg");
const en_alpatLogoComp = require("url:../../../assets/alpat-logo.svg");
const en_sermaLogoComp = require("url:../../../assets/serma-logo.svg");
const en_pilgrimEnergyLogoComp = require("url:../../../assets/pilgrim-energy.svg");

class en_CompaniesComponent extends HTMLElement {
    shadow = this.attachShadow({ mode: "open" });
    constructor() {
        super();

        let style = document.createElement("style");
        style.textContent = `
            .companies-container {
                height: 180vh;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 780px) {
                .companies-container {
                    height: 200vh;
                }
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 700px) {
                .companies-container {
                    height: 225vh;
                }
            }

            @media (min-width: 768px) and (max-width: 1440px) and (max-height: 650px) {
                .companies-container {
                    height: 230vh;
                }
            }

            @media (min-width: 1440px) {
                .companies-container {
                    height: 260vh;
                }
            }

            @media (min-width: 1440px) and (max-height: 780px) {
                .companies-container {
                    height: 275vh;
                }
            }

            @media (min-width: 1440px) and (max-height: 700px) {
                .companies-container {
                    height: 290vh;
                }
            }

            .companies__side-bar {
                width: 40%;
                height: calc(100% - 25px);
                padding: 0 25px;
                padding-top: 25px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                border-right: 1px solid var(--black-color);
                border-top: 1px solid var(--black-color);
                border-bottom: 1px solid var(--black-color);
            }

            @media (min-width: 768px) {
                .companies__side-bar {
                    height: calc(100% - 180px);
                    padding: 90px 50px;
                }
            }

            @media (min-width: 960px) {
                .companies__side-bar {
                    width: 42%;
                }
            }

            @media (min-width: 1280px) {
                .companies__side-bar {
                    height: calc(100% - 300px);
                    padding: 150px 50px;
                    width: 38%;
                }
            }

            @media (min-width: 1440px) {
                .companies__side-bar {
                    width: 42%;
                    height: calc(100% - 330px);
                    padding: 165px 100px;
                }
            }

            @media (min-width: 1850px) {
                .companies__side-bar {
                    width: 45%;
                }
            }


            .side-bar__title {
                width: 100%;
                margin: 0;
                margin-bottom: 27px;
                font: 700 20px var(--jakarta-font);
                color: var(--black-color);
                text-align: left;
                transition: all .4s;
            }
            
            .side-bar__title:hover {
                cursor: pointer;
            }

            @media (min-width: 960px) {
                .side-bar__title {
                    width: 100%;
                    font-size: 27px;
                }
            }

            @media (min-width: 1280px) {
                .side-bar__title {
                    font-size: 30px;
                }
            }

            @media (min-width: 1440px) {
                .side-bar__title {
                    font-size: 35px;
                }
            }

            @media (min-width: 1850px) {
                .side-bar__title {
                    font-size: 47px;
                }
            }

            .side-bar__content {
                width: 100%;
                position: sticky;
                top: 45%;
            }

            @media (min-width: 1850px) {
                .side-bar__content {
                    margin-top: 55px; 
                }
            }

            .content__text {
                width: 100%;
                font: 500 12px var(--jakarta-font); 
                color: var(--black-color);
            }

            @media (min-width: 960px) {
                .content__text {
                    font-size: 14px;
                }
            }

            @media (min-width: 1280px) {
                .content__text {
                    font-size: 16px;
                }
            }

            @media (min-width: 1440px) {
                .content__text {
                    font-size: 20px;
                }
            }

            @media (min-width: 1850px) {
                .content__text {
                    font-size: 24px;
                }
            }

            .companies__content-cards {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 25px;
                padding-right: 50px;
                border-top: 1px solid var(--black-color);
                border-bottom: 1px solid var(--black-color);
                gap: 25px;
            }

            @media (min-width: 960px) {
                .companies__content-cards {
                    width: 80%;
                    padding: 0;
                }
            }

            @media (min-width: 1280px) {
                .companies__content-cards {
                    gap: 30px;
                }
            }

            @media (min-width: 1440px) {
                .companies__content-cards {
                    gap: 50px;
                }
            }

            @media (min-width: 1850px) {
                .companies__content-cards {
                    width: 85%;
                    gap: 100px;
                }
            }

            .content__left-side {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                padding-top: 25vh;
            }

            @media (min-width: 1280px) {
                .content__left-side {
                    padding-top: 35vh;
                }
            }

            @media (min-width: 1850px) {
                .content__left-side {
                    padding-top: 30vh;
                }
            }

            .content__right-side {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .content__left-side,
            .content__right-side {
                gap: 25px;
                padding-top: 25vh;
            }

            @media (min-width: 1280px) {
                .content__left-side,
                .content__right-side {
                    gap: 30px;
                    padding-top: 35vh;
                }
            }

            @media (min-width: 1440px) {
                .content__left-side,
                .content__right-side {
                    gap: 50px;
                }
            }

            @media (min-width: 1850px) {
                .content__left-side,
                .content__right-side {
                    gap: 100px;
                    padding-top: 30vh;
                }
            }
            
        `;

        this.render();
        this.shadow.appendChild(style);
    }

    render() {
        this.shadow.innerHTML = `
            <div class="companies-container">
                <div class="companies__side-bar">
                    <div class="side-bar__content">
                        <h3 class="side-bar__title">
                        Our group
                        </h3>
                        <p class="content__text">
                        The Group is comprised of production and service companies in different industries, principally in media, construction, energy and mining.
                        </p>
                    </div>
                </div>
                <div class="companies__content-cards">
                    <div class="content__left-side">
                        <en-company-card path="/en/company/indalo-media" src="${en_indaloMediaLogoComp}" rectangularLogo>
                            One of the principal multimedia companies in Argentina. It has a strong presence in the television, radio, graphic and digital media industries and in the production of audiovisual content.
                        </en-company-card>
                        <en-company-card path="/en/company/cpc" src="${en_cpcLogoComp}" squareLogo>
                            Leading company in the implementation of large-scale public work projects.
                        </en-company-card>
                        <en-company-card path="/en/company/alpat" src="${en_alpatLogoComp}" squareLogo>
                            The only sodium carbonate producer in Latin America.
                        </en-company-card>
                    </div>
                    <div class="content__right-side">
                        <en-company-card path="/en/company/oil-m&s" src="${en_oilLogoComp}" rectangularLogo>
                            Company providing comprehensive solutions to the hydrocarbon and mining industries throughout the country.
                        </en-company-card>
                        <en-company-card path="/en/company/providencia" src="${en_providenciaLogoComp}" rectangularLogo>
                            Insurance company specialized in providing a wide range of services to individuals and companies through brokers and producers.
                        </en-company-card>
                        <en-company-card path="/en/company/serma" src="${en_sermaLogoComp}" squareLogo>
                            Providing environmental engineering services to companies in the hydrocarbon and mining industries. 
                        </en-company-card>
                    </div>
                </div>
            </div>
        `;
    }
}

customElements.define("en-companies-content", en_CompaniesComponent);
