const heroLeftArrow = require("url:../../assets/icons/left-arrow.svg");
const heroRightArrow = require("url:../../assets/icons/right-arrow.svg");

const firstHeroImage = require("url:../../assets/images/pilgrim-bg.png");
const secondHeroImage = require("url:../../assets/images/second-hero-image.png");
const thirdHeroImage = require("url:../../assets/images/third-hero-image.png");
const fourthHeroImage = require("url:../../assets/images/indalo-media-bg.png");
const fifthHeroImage = require("url:../../assets/images/providencia-bg-2.png");

class HeroSliderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		const style = document.createElement("style");

		style.textContent = `
        
        .carousel-container {
            width: 100vw;
            height: 90vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 45px;
        } 

        @media (min-width: 1850px) {
            .carousel-container {
                width: 100vw;
                height: 90vh;
                max-width: none;
                max-height: none;
            }
        }

        .carousel {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
        }

        .sliders-container {
            width: 500%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .7s ease;
        }
        
        .sliders__item {
            width: 100vw;
            height: 100%;
        }
        
        .sliders__item.first-item {
            height: 90vh;
            background-size: cover;
            background-position: bottom center;
            background-image: url(${firstHeroImage});
        }

        .sliders__item.second-item {
            height: 90vh;
            background-size: cover;
            background-position: bottom center;
            background-image: url(${secondHeroImage});
        }

        .sliders__item.third-item {
            height: 90vh;
            background-size: cover;
            background-position: bottom center;
            background-image: url(${thirdHeroImage});
        }

        .sliders__item.fourth-item {
            height: 90vh;
            background-size: cover;
            background-position: bottom center;
            background-image: url(${fourthHeroImage});
        }

        .sliders__item.fifth-item {
            height: 90vh;
            background-size: cover;
            background-position: bottom right;
            background-image: url(${fifthHeroImage});
        }

        .item__content {
            position: absolute;
            left: 10%;
            top: 40%;
            max-width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            z-index: 100;
        }

        @media (min-width: 768px) {
            .item__content {
                max-width: 70%;
            }
        }

        @media (min-width: 1850px) {
            .item__content {
                max-width: 50%;
            }
        }

        .item__title {
            width: 100%;
        }

        .item__text {
            margin-top: 10px;
        }

        @media (min-width: 768px) {
            .item__text {
                margin-top: 20px;
            }
        }

        @media (min-width: 1440px) {
            .item__text {
                margin-top: 30px;
            }
        }

        @media (min-width: 1850px) {
            .item__text {
                margin-top: 35px;
            }
        }

        .item__controls {
            width: 100%;
            margin-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
        }

        .left-arrow, 
        .right-arrow {
            width: 43px;    
            opacity: 0.7;
        }

        .left-arrow:hover, 
        .right-arrow:hover {
            cursor: pointer;
            opacity: 1;
        }
        
        @media (min-width: 1280px) {
            .left-arrow, 
            .right-arrow {
                width: 45px;
            }    
        }

        @media (min-width: 1850px) {
            .left-arrow, 
            .right-arrow {
                width: 48px;    
            }    
        }

        `;

		this.render();

		this.shadow.appendChild(style);
	}

	addListeners() {
		const carouselEl: any = this.shadow.querySelector(".carousel");
		const SlContainerEl: any =
			this.shadow.querySelector(".sliders-container");

		const slidersItemsEl = this.shadow.querySelectorAll(".sliders__item");

		const leftArrowEl: any = this.shadow.querySelector(".left-arrow");
		const rightArrowEl: any = this.shadow.querySelector(".right-arrow");

		let isDragging = false,
			startPos = 0,
			currentTranslate = 0,
			prevTranslate = 0,
			animationID,
			currentIndex = 0;

		slidersItemsEl.forEach((slide, index) => {
			slide.addEventListener("touchstart", touchStart(index));
			slide.addEventListener("touchend", touchEnd);
			slide.addEventListener("touchmove", touchMove);
		});

		function getPositionX(event) {
			return event.type.includes("mouse")
				? event.pageX
				: event.touches[0].clientX;
		}

		function touchStart(index) {
			return function (event) {
				currentIndex = index;
				startPos = getPositionX(event);
				isDragging = true;
				animationID = requestAnimationFrame(animation);
			};
		}

		function touchMove(event) {
			if (isDragging) {
				const currentPosition = getPositionX(event);
				currentTranslate = prevTranslate + currentPosition - startPos;
			}
		}

		function touchEnd() {
			cancelAnimationFrame(animationID);
			isDragging = false;

			const offset = SlContainerEl.getAttribute("offset");
			const movedBy = currentTranslate - prevTranslate;

			if (movedBy > 100 && offset == "0") {
				SlContainerEl.style.transform = "translateX(-80%)";
				SlContainerEl.setAttribute("offset", "4");
			} else if (movedBy > 100 && offset == "4") {
				SlContainerEl.style.transform = "translateX(-60%)";
				SlContainerEl.setAttribute("offset", "3");
			} else if (movedBy > 100 && offset == "3") {
				SlContainerEl.style.transform = "translateX(-40%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (movedBy > 100 && offset == "2") {
				SlContainerEl.style.transform = "translateX(-20%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (movedBy > 100 && offset == "1") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}

			if (movedBy < -100 && offset == "0") {
				SlContainerEl.style.transform = "translateX(-20%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (movedBy < -100 && offset == "1") {
				SlContainerEl.style.transform = "translateX(-40%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (movedBy < -100 && offset == "2") {
				SlContainerEl.style.transform = "translateX(-60%)";
				SlContainerEl.setAttribute("offset", "3");
			} else if (movedBy < -100 && offset == "3") {
				SlContainerEl.style.transform = "translateX(-80%)";
				SlContainerEl.setAttribute("offset", "4");
			} else if (movedBy < -100 && offset == "4") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}
		}

		function animation() {
			if (isDragging) requestAnimationFrame(animation);
		}

		leftArrowEl.addEventListener("click", (e) => {
			const target = e.target as any;
			const position = target.getAttribute("position");
			const offset = SlContainerEl.getAttribute("offset");

			if (position == "previous" && offset == "0") {
				SlContainerEl.style.transform = "translateX(-80%)";
				SlContainerEl.setAttribute("offset", "4");
			} else if (position == "previous" && offset == "4") {
				SlContainerEl.style.transform = "translateX(-60%)";
				SlContainerEl.setAttribute("offset", "3");
			} else if (position == "previous" && offset == "3") {
				SlContainerEl.style.transform = "translateX(-40%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (position == "previous" && offset == "2") {
				SlContainerEl.style.transform = "translateX(-20%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (position == "previous" && offset == "1") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}
		});

		rightArrowEl.addEventListener("click", (e) => {
			const target = e.target as any;
			const position = target.getAttribute("position");
			const offset = SlContainerEl.getAttribute("offset");

			if (position == "next" && offset == "0") {
				SlContainerEl.style.transform = "translateX(-20%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (position == "next" && offset == "1") {
				SlContainerEl.style.transform = "translateX(-40%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (position == "next" && offset == "2") {
				SlContainerEl.style.transform = "translateX(-60%)";
				SlContainerEl.setAttribute("offset", "3");
			} else if (position == "next" && offset == "3") {
				SlContainerEl.style.transform = "translateX(-80%)";
				SlContainerEl.setAttribute("offset", "4");
			} else if (position == "next" && offset == "4") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}
		});

		setInterval(() => {
			const offset = SlContainerEl.getAttribute("offset");

			if (offset == "0") {
				SlContainerEl.style.transform = "translateX(-20%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (offset == "1") {
				SlContainerEl.style.transform = "translateX(-40%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (offset == "2") {
				SlContainerEl.style.transform = "translateX(-60%)";
				SlContainerEl.setAttribute("offset", "3");
			} else if (offset == "3") {
				SlContainerEl.style.transform = "translateX(-80%)";
				SlContainerEl.setAttribute("offset", "4");
			} else if (offset == "4") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}
		}, 5000);
	}

	render() {
		this.shadow.innerHTML = `
        <div class="carousel-container">
            <div class="item__content">
                <title-comp class="item__title" variant="hero left-sided no-margin jakarta bold" scheme="light">SOMOS</title-comp>
                <title-comp class="item__title" variant="hero left-sided no-margin jakarta bold" scheme="light">GRUPO INDALO</title-comp>
                <text-comp class="item__text" variant="slider no-margin left-sided" scheme="f-light">
                    ${this.textContent}
                </text-comp>
                <div class="item__controls">
                    <img class="left-arrow" src="${heroLeftArrow}" position="previous">
                    <img class="right-arrow" src="${heroRightArrow}" position="next">
                </div>
            </div>
            <div class="carousel">
                <div class="sliders-container" offset="0">
                    <div class="first-item sliders__item" offset="0">
                    </div>
                    <div class="second-item sliders__item" offset="1">
                    </div>
                    <div class="third-item sliders__item" offset="2">
                    </div>
                    <div class="fourth-item sliders__item" offset="3">
                    </div>
                    <div class="fifth-item sliders__item" offset="4">
                    </div>
                </div>
            </div>
        </div>
        `;
		this.addListeners();
	}
}
customElements.define("hero-slider", HeroSliderComponent);
