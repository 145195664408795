const DownIcon = require("url:../../assets/icons/gridicons_dropdown.svg");

class translateComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
		.header__language-select {
			display: flex;
			justify-content: space-between;
			position: relative;

			width: 5.25rem;
			height: 2rem;

			cursor: pointer;

			box-sizing: border-box;
			border: 1px solid #FFF;
			color: #fff;
		}
		.header__options {
			display: flex;
			flex-direction: column;
			gap: 2px;
			width: 100%;
			height: 1.4rem;
			overflow: hidden;
			transition: height 0.5s;
		}
		.header__option {
			font-family: Plus Jakarta Sans;
			font-size: 1.125rem;
			font-weight: 700;
			line-height: 1.375rem;
			padding: 4px 8px;
			
		}
		.header__option .light {
			color: var(--white-color);
			text-decoration: none;
		}
		.icon-down {
			position: absolute;
			top: 3px;
			right: 8px;
			transition: transform 0.5s;
		}
		.header__language-select.open {
			backgroud: red;
		}
		.header__language-select.open .header__options {
			height: 4rem;
		}
		.header__language-select.open .header__option:not(:first-child) {
			background: rgba(43, 43, 43, 0.57);
		}
		.header__language-select.open .icon-down {
			transform: rotate(180deg);
		}
		}
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
			<div class="header__language-select">
				<div class="header__options">
					<div id="es" class="header__option">
						<a class="light" href="">ESP</a>
					</div>
					<div id="en" class="header__option">
						<a class="light" href="">ENG</a>
					</div>
				</div>
				<img src="${DownIcon}" class="icon-down"/>
			</div>
        `;
	}

	addListeners() {
		//START Translate Button
		const languageSelect = this.shadow.querySelector(".header__language-select");
		const englishOption = this.shadow.querySelector("#en");
		const spanishOption = this.shadow.querySelector("#es");

		const url = window.location.href;
		const isPresent = url.includes("/en");

		if (isPresent) {
			englishOption.parentNode.prepend(englishOption)
			englishOption.style.pointerEvents = 'none';
		} else {
			spanishOption.style.pointerEvents = 'none';
		}

		languageSelect?.addEventListener("click", (e) => {
			languageSelect.classList.toggle("open")
		});

		const options = this.shadow.querySelectorAll(".header__option");

		options.forEach((option, index) => index > 0 && option.addEventListener("click", (e) => {
			const url = new URL(window.location.href);
			const path = url.pathname.substr(1);
			const fragment = url.hash;

			if (option.id == "en") {
				option.querySelector("a").href = `/en${fragment ? fragment : "/" + path}`;
			} else {
				const newpath = path.replace("en/", "");
				option.querySelector("a").href = `/${fragment ? fragment : newpath}`;
			}
			if (fragment) {
				setTimeout(() => {
					const targetElement = document.querySelector(fragment)
					targetElement.scrollIntoView({ behavior: "smooth" });
				}, 100);
			}
		}))
		//END Translate Button
	}
}

customElements.define("translate-comp", translateComponent);
