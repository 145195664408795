const playButton = require("url:../../assets/icons/play-button.svg");
const pauseButton = require("url:../../assets/icons/pause-button.svg");

class VideoEmbedComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
		.video-container {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
        .video {
			width: 100%;
            height: 100%;
            min-height: 255px;
			object-fit: cover;
			object-position: center;
			border: none;
			border-radius: 4px;
			filter: brightness(0.6);
        }

		@media (min-width: 768px) {
			.video {
				border-radius: 8px;
				max-width: 70vw;
				max-height: 70vh;
			}
		}
		
		.video:active {
			filter: brightness(1);
		}

		.video-controls {
			position: absolute;
		}

		.play-btn.show,
		.pause-btn.show {
			opacity: 0.7;
			width: 50px;
			transition: all .4s;
		}

		.play-btn.hide,
		.pause-btn.hide {
			opacity: 0;
			width: 50px;
			transition: all .4s;
		}

		.play-btn:hover,
		.pause-btn:hover {
			opacity: 1;
			cursor: pointer;
			transition: all .4s;
		}
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
		    <div class="video-container">
		        <video class="video" src="${this.getAttribute(
					"src"
				)}" type="video/mp4" allowfullscreen="" controlslist="nodownload" playsinline></video>
				<div class="video-controls">
					<img class="play-btn show" src="${playButton}" play="${playButton}" pause="${pauseButton}">
				<div>
			</div>
		`;
	}

	addListeners() {
		const video: HTMLVideoElement = this.shadow.querySelector(".video");
		const playBtn = this.shadow.querySelector(".play-btn");

		video.volume = 0.5;

		function togglePlay() {
			const play = playBtn.getAttribute("play");
			const pause = playBtn.getAttribute("pause");

			if (video.paused) {
				playBtn.setAttribute("src", pause);
				video.setAttribute("controls", "controls");
				video.style.filter = "brightness(1)";
				video.play();
			} else {
				playBtn.setAttribute("src", play);
				video.style.filter = "brightness(.6)";
				video.pause();
			}
			playBtn.classList.toggle("paused");
		}

		function showControls() {
			playBtn.classList.remove("hide");
			playBtn.classList.add("show");
		}

		function hideControls() {
			playBtn.classList.remove("show");
			playBtn.classList.add("hide");
		}

		function reloadVideo() {
			video.load();
			const play = playBtn.getAttribute("play");
			video.removeAttribute("controls");
			playBtn.setAttribute("src", play);
			playBtn.classList.remove("hide");
			playBtn.classList.add("show");
		}

		playBtn.addEventListener("click", togglePlay);
		video.addEventListener("click", (e) => {
			e.preventDefault();
		});
		video.addEventListener("ended", reloadVideo);
		video.addEventListener("mouseover", showControls);
		video.addEventListener("mouseleave", hideControls);
	}
}
customElements.define("video-embed", VideoEmbedComponent);
