class LinkComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .header {
            font: 700 18px var(--jakarta-font);
            text-decoration: none;
        }

        .menu {
            font: 700 26px var(--jakarta-font);
            padding: 25px;
            line-height: 39px;
            text-decoration: none;
        }

        .submenu {
            padding: 0;
            font: 500 26px var(--jakarta-font);
        }

        @media (min-width: 769px) {
            .menu {
                font-size: 15px;
            }
        }

        @media (min-width: 1280px) {
            .menu {
                font-size: 18px;
            }
        }

        .card {
            font: 700 14px var(--aeonik-font);
        }

        .dark {
            color: var(--black-color);
        }

        .light {
            color: var(--white-color);
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <a class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            " href="${this.getAttribute("href")}">
            ${this.textContent}
            </a>
        `;
	}
}
customElements.define("link-comp", LinkComponent);
