class en_accordionComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
		.accordion-item {
			width: 100%;
			height: 100%;
			margin: 0 auto;
			text-align: left;
		}

		.accordion-item:hover {
			cursor: pointer;
		}

		.accordion-item__wrapper {
			padding-bottom: 15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			text-align: center;
		}

		@media (min-width: 768px) {
			.accordion-item__wrapper {
				width: calc(100% - 100px);
				height: auto;
			}
		}

		.accordion-item__inverse-wrapper {
			flex-direction: row-reverse;	
		}

		.accordion-item__title {
            font: 700 26px var(--jakarta-font);
			color: var(--white-color);
			padding: 0 25px;
            text-decoration: none;
        }

		.accordion-item__toggle {
			font: 700 26px var(--jakarta-font);
			color: var(--white-color);
			margin: 0;
            text-decoration: none;
			transition: transform .4s ease;
		}

		.accordion-item__number {
			margin-left: 10px;
			font: 400 15px var(--aeonik-font);
		}

		.accordion-item__content {
			padding: 0 25px;
			display: none;
			overflow: auto;
		}
		
		.accordion-item__content.deactive {
			max-height: 0px;
		}

		.accordion-item__content.active {
			height: 100px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 15px;
		}

		@media (min-height: 840px) {
			.accordion-item__content.active {
				height: 200px;
			}
		}
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
        <div class="accordion-item">
			<div class="accordion-item__wrapper ${this.hasAttribute("inverse")
				? "accordion-item__inverse-wrapper"
				: ""
			}">
				<div class="accordion-item__title">
            		${this.textContent}
				</div>
				<h3 class="accordion-item__toggle ${this.hasAttribute("active") ? "active" : ""
			}">${this.hasAttribute("active") ? "-" : "+"}</h3>
			</div>
			<div class="accordion-item__content deactive ${this.hasAttribute("active") ? "active" : ""
			}">
				<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/indalo-media">Indalo Media</link-comp>
				<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/oil-m&s">Oil M&S</link-comp>
				<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/cpc">CPC</link-comp>
				<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/providencia">Providencia</link-comp>
				<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/alpat">Alpat</link-comp>
				<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/serma">Serma</link-comp>
			</div>
        </div>
        `;
	}

	addListeners() {
		const accordionItemEl = this.shadow.querySelector(".accordion-item");

		const itemContentEl = this.shadow.querySelector(
			".accordion-item__content"
		);

		const itemToggleEl = this.shadow.querySelector(
			".accordion-item__toggle"
		);

		itemContentEl.addEventListener(
			"animationend",
			() => {
				if (
					itemContentEl.classList.contains("deactive") &&
					itemContentEl.classList.contains("active")
				) {
					itemContentEl.classList.toggle("deactive");
				}
			},
			{ once: true }
		);

		accordionItemEl.addEventListener("click", () => {
			itemContentEl.classList.toggle("active");
			itemContentEl.classList.toggle("deactive");
			itemToggleEl.classList.toggle("active");
		});
	}
}
customElements.define("en-accordion-item", en_accordionComponent);
