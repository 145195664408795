const footerIndaloLogo = require("url:../../assets/images/footer-logo.svg");
const grupoIndalo = require("url:../../assets/grupo-indalo.svg");

const whiteInstagramLogo = require("url:../../assets/icons/instagram.svg");
const whiteLinkedInLogo = require("url:../../assets/icons/linkedin.svg");
const whiteTwitterLogo = require("url:../../assets/icons/twitter.svg");

class FooterComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .footer {
            width: 100vw;
            height: 25vh;
            min-height: 210px;
            padding-bottom: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-image: url(${footerIndaloLogo});
            background-color: var(--black-color);
            background-position: 0 35%;
            background-size: 75px;
            background-repeat: no-repeat;
        }

        @media (min-width: 600px) {
            .footer {
                background-size: 80px;
            }
        }

        @media (min-width: 768px) {
            .footer {
                width: 100vw;
                height: 30vh;
                padding: 50px 0;
                background-size: 15%;
                background-position: left center;
                background-color: var(--dark-gray-color);
            }
        }

        @media (min-width: 960px) {
            .footer {
                width: 99.7vw;
                height: 100%;
                max-height: 310px;
                padding: 50px 0;
                background-size: 10%;
            }
        }

        @media (min-width: 1440px) {
            .footer {
                justify-content: flex-end;
                padding: 100px 0;
                background-size: 12%;
            }
        }

        @media (min-width: 1850px) {
            .footer {
                background-size: 10%;
            }
        }

        .footer__content {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        @media (min-width: 768px) {
            .footer__content {
                max-width: none;
                justify-content: space-between;
                align-items: center;
            }
        }

        .content__left-side {
            width: 40%;
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            gap: 15px;
        }

        @media (min-width: 768px) {
            .content__left-side {
                height: 100%;
                justify-content: center;
            }
        }

        @media (min-width: 960px) {
            .content__left-side {
                width: 45%;
            }
        }


        .left-side__logo {
            width: 100px;
            height: auto;
        }

        @media (min-width: 550px) {
            .left-side__logo {
                width: 150px;
            }
        }

        @media (min-width: 768px) {            
            .left-side__logo {
                padding-left: 50px;
                width: 205px;
            }
        }

        @media (min-width: 960px) {            
            .left-side__logo {
                padding-left: 50px;
                width: 220px;
            }
        }

        @media (min-width: 1280px) {
            .left-side__logo {
                width: 300px;
            }
        }

        @media (min-width: 1440px) {
            .left-side__logo {
                width: 325px;
            }
        }

        @media (min-width: 1850px) {            
            .left-side__logo {
                width: 400px;
            }
        }

        .content__right-side {
            width: 40%;
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
        }

        @media (min-width: 768px) {
            .content__right-side {
                width: 20%;
                height: 100%;
            }
        }

        @media (min-width: 960px) {
            .content__right-side {
                width: 24%;
            }
        }

        @media (min-width: 1280px) {
            .content__right-side {
                width: 22%;
            }
        }

        .content__nav-links {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
        }

        .nav-links__title {
            width: 65%;
            font: 400 14px var(--roboto-font);
            color: var(--white-color);
            text-align: left;
        }

        @media (min-width: 768px) {
            .nav-links__title {
                width: 100%;
                font-family: var(--jakarta-font);
                font-size: 16px;
                text-decoration: none;
            }
        }

        @media (min-width: 960px) {
            .nav-links__title {
                font-size: 19px;
                margin-bottom: 10px;
            }
        }

        @media (min-width: 1440px) {
            .nav-links__title {
                margin-bottom: 20px;
                font-size: 24px;
            }
        }

        .nav-links__title.bold {
            font-weight: 700;
            font-size: 18px;
        }

        @media (min-width: 768px) {
            .nav-links__title.bold {
                font-size: 20px;
            }
        }

        @media (min-width: 960px) {
            .nav-links__title.bold {
                margin-top: 0;
                font-size: 24px;
            }
        }


        @media (min-width: 1280px) {
            .nav-links__title.bold {
                font-size: 28px;
            }
        }

        @media (min-width: 1440px) {
            .nav-links__title.bold {
                font-size: 32px;
            }
        }

        .content__social-media {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 15px;
        }

        @media (min-width: 768px) {
            .content__social-media {
                width: 150px;
                justify-content: flex-start;
            }    
        }

        @media (min-width: 960px) {
            .content__social-media {
                width: 170px;
                justify-content: flex-start;
            }    
        }

        @media (min-width: 1280px) {
            .content__social-media {
                width: 250px;
            }    
        }

        @media (min-width: 1440px) {
            .content__social-media {
                width: 270px;
            }    
        }

        @media (min-width: 1850px) {
            .content__social-media {
                width: 350px;
            }    
        }

        .content__social-media-logo {
            width: 25px;
        }

        @media (min-width: 960px) {
            .content__social-media-logo {
                width: 30px;
            }
        }

        @media (min-width: 1280px) {
            .content__social-media-logo {
                width: 40px;
            }
        }
        
        @media (min-width: 1440px) {
            .content__social-media-logo {
                width: 45px;
            }
        }

        @media (min-width: 1850px) {
            .content__social-media-logo {
                width: 54px;
            }
        }

        .social-media__link {
            text-decoration: none;
        }

        .content__details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
        }

        .details__first-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 15px;
        }

        .footer__details {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        @media (min-width: 768px) {
            .footer__details {
                width: 22%;
                height: 100%;
                padding-right: 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: flex-start;
            }
        }

        @media (min-width: 960px) {
            .footer__details {
                width: 30%;
                padding: 0;
            }
        }

        @media (min-width: 1280px) {
            .footer__details {
                width: 20%;
            }
        }

        @media (min-width: 1280px) {
            .footer__details {
                width: 24%;
            }
        }

        @media (min-width: 1440px) {
            .footer__details {
                width: 24%;
            }
        }

        .desktop {
            display: none;
        }

        @media (min-width: 768px) {            
            .mobile {
                display: none;
            }

            .desktop {
                display: inherit;
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	//! footer__details (Mobile y Desktop) tiene display: none; (estilos inline)
	render() {
		this.shadow.innerHTML = `
        <footer class="footer">
            <div class="footer__content">
				<div class="content__left-side">
                    <img class="left-side__logo" src="${grupoIndalo}">
                    <div class="content__social-media">
                        <a class="social-media__link" href="https://instagram.com/grupoindalooficial?igshid=YmMyMTA2M2Y=" target="_blank">
                            <img class="content__social-media-logo" src="${whiteInstagramLogo}" alt="Instagram">
                        </a>
                        <a class="social-media__link" href="https://www.linkedin.com/company/grupo-indalo/posts/?
                        feedView=all&viewAsMember=true" target="_blank">
                            <img class="content__social-media-logo" src="${whiteLinkedInLogo}" alt="LinkedIn">
                        </a>
                        <a class="social-media__link" href="https://twitter.com/indaloprensa?s=11&t=NnNu0h-1-eNnbilJH-dz6w" target="_blank">
                            <img class="content__social-media-logo" src="${whiteTwitterLogo}" alt="Twitter">
                        </a>
                    </div>
				</div>
                <div class="content__right-side">
                    <h3 class="nav-links__title bold">Menu</h3>
                    <div class="content__nav-links">
                        <a href="#hero" class="nav-links__title">Inicio</a>
                        <a href="#about-us" class="nav-links__title">Quiénes somos</a>
                        <a href="#companies" class="nav-links__title">Nuestro grupo</a>
                        <a href="#rse" class="nav-links__title">Sustentabilidad</a>
                    </div>
                </div>
                <div style="display: none" class="footer__details desktop">
                    <text-comp class="hidden" variant="footer-details padding-applied" scheme="f-light">
                        Política de Privacidad
                    </text-comp>
                    <text-comp class="hidden" variant="footer-details padding-applied" scheme="f-light">
                        Condiciones de uso
                    </text-comp>
                    <text-comp class="hidden" variant="footer-details padding-applied" scheme="f-light">
                        Privacidad y cookies
                    </text-comp>
                    <text-comp class="hidden" variant="footer-details padding-applied" scheme="f-light">
                        Declaración de Accesibilidad
                    </text-comp>
                </div>
			</div>
			<div style="display: none" class="footer__details mobile">
				<text-comp class="hidden" variant="footer-details padding-applied" scheme="light">
					Política de Privacidad
				</text-comp>
				<text-comp class="hidden" variant="footer-details padding-applied" scheme="light">
					Condiciones de uso
				</text-comp>
				<text-comp class="hidden" variant="footer-details padding-applied" scheme="light">
					Privacidad y cookies
				</text-comp>
				<text-comp class="hidden" variant="footer-details padding-applied" scheme="light">
					Declaración de Accesibilidad
				</text-comp>
			</div>
        </footer>
        `;
	}

	addListeners() {}
}
customElements.define("footer-comp", FooterComponent);
