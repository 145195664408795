import { Router } from "@vaadin/router";

const root = document.querySelector(".root");

const router = new Router(root);

router.setRoutes([
	{ path: "/", component: "home-page" },
	{ path: "/index.html", component: "home-page" },
	{ path: "/en", component: "en-home-page" },
	{ path: "/en/index.html", component: "en-home-page" },
	{ path: "/company/indalo-media", component: "indalo-media-page" },
	{ path: "/company/oil-m&s", component: "oil-combustibles-page" },
	{ path: "/company/cpc", component: "cpc-page" },
	{ path: "/company/providencia", component: "providencia-page" },
	{ path: "/company/alpat", component: "alpat-page" },
	{ path: "/company/serma", component: "serma-page" },
	{ path: "/company/pilgrim", component: "pilgrim-page" },
	{ path: "/en/company/indalo-media", component: "en-indalo-media-page" },
	{ path: "/en/company/oil-m&s", component: "en-oil-combustibles-page" },
	{ path: "/en/company/cpc", component: "en-cpc-page" },
	{ path: "/en/company/providencia", component: "en-providencia-page" },
	{ path: "/en/company/alpat", component: "en-alpat-page" },
	{ path: "/en/company/serma", component: "en-serma-page" },
	{ path: "/en/company/pilgrim", component: "en-pilgrim-page" },

]);
