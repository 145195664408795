class TitleComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .jakarta {
             font-family: var(--jakarta-font);
             line-height: 0.8;
        }

        .thick-line { 
            line-height: 1.2;
        }
        
        .regular {
            font-weight: 400;
        }

        .medium {
            font-weight: 500;
        }

        .semi-bold {
            font-weight: 600;
        }

        .bold {
            font-weight: 700;
        }

        .extra-bold {
            font-weight: 800;
        }

        .montserrat {
            font-family: var(--montserrat-font);
        }

        .hero {
            width: 100%;
            font-size: 33px;
        }

        @media (min-width: 500px) {
            .hero {
                font-size: 37px;
            }
        }

        @media (min-width: 768px) {
            .hero {
                font-size: 48px;
            }
        }

        @media (min-width: 960px) {
            .hero {
                font-size: 55px;
            }
        }

        @media (min-width: 1280px) {
            .hero {
                font-size: 67px;
            }
        }

        @media (min-width: 1440px) {
            .hero {
                font-size: 70px;
            }
        }

        @media (min-width: 1850px) {
            .hero {
                font-size: 80px;
            }
        }

        .hero.company-name {
            font-size: 37px;
            margin: 0;
        }

        @media (min-width: 768px) {
            .hero.company-name {
                font-size: 47px;
            }
        }

        @media (min-width: 960px) {
            .hero.company-name {
                font-size: 55px;
            }
        }

        @media (min-width: 1280px) {
            .hero.company-name {
                font-size: 65px;
            }
        }

        @media (min-width: 1440px) {
            .hero.company-name {
                font-size: 75px;
            }
        }

        @media (min-width: 1850px) {
            .hero.company-name {
                font-size: 95px;
            }
        }

        .hero.company-slogan {
            margin: 0;
        }

        @media (min-width: 768px) {
            .hero.company-slogan {
                width: 80%;
                font-size: 37px;
            }
        }

        @media (min-width: 960px) {
            .hero.company-slogan {
                width: 70%;
                font-size: 45px;
            }
        }

        @media (min-width: 1280px) {
            .hero.company-slogan {
                width: 67%;
                font-size: 49px;
            }
        }


        @media (min-width: 1440px) {
            .hero.company-slogan {
                font-size: 51px;
            }
        }

        @media (min-width: 1850px) {
            .hero.company-slogan {
                font-size: 67px;
            }
        }

        .hero.company-slogan.minimized {
            font-size: 30px;
            margin: 0;
        }

        @media (min-width: 768px) {
            .hero.company-slogan.minimized {
                width: 80%;
                font-size: 33px;
            }
        }

        @media (min-width: 960px) {
            .hero.company-slogan.minimized {
                width: 70%;
                font-size: 37px;
            }
        }

        @media (min-width: 1280px) {
            .hero.company-slogan.minimized {
                width: 67%;
                font-size: 46px;
            }
        }

        @media (min-width: 1440px) {
            .hero.company-slogan.minimized {
                font-size: 50px;
            }
        }

        @media (min-width: 1850px) {
            .hero.company-slogan.minimized {
                font-size: 63px;
            }
        }

        .section {
            width: calc(100% - 70px);
            padding: 40px 35px;
            margin: 0;
            font-size: 27px;
            text-align: left;
        }

        @media (min-width: 390px) {
            .section {
                font-size: 30px;
            }
        }

        @media (min-width: 768px) {
            .section {
                width: calc(100% - 100px);
                padding: 15px 50px;
                font-size: 20px;
            }
        }

        @media (min-width: 1440px) {
            .section {
                width: calc(100% - 70px);
                padding: 15px 35px;
            }
        }

        .section.medium {
            font-size: 28px;
            line-height: 45px;
        }

        @media (min-width: 768px) {
            .section.medium {
                font-size: 30px;
            }
        }
        
        @media (min-width: 1280px) {
            .section.medium {
                font-size: 33px;
            }
        }

        @media (min-width: 1440px) {
            .section.medium {
                font-size: 36px;
            }
        }

        @media (min-width: 1850px) {
            .section.medium {
                font-size: 47px;
            }
        }

        .section.margin-top {
            margin-top: 45px;
        }

        .card {
            font: 500 29px var(--aeonik-font);
            margin: 0;
            padding: 10px 0;
        }

        @media (min-width: 1440px) {
            .card {
                font-size: 43px;
            }
        }

        .dark {
            color: var(--black-color);
        }

        .light {
            color: var(--white-color);
        }

        .hundred-percent-width {
            width: 100%;
        }

        .left-sided {
            text-align: left;
        }

        .middle-sided {
            text-align: center;
        }

        .right-sided {
            text-align: right;
        }

        .no-x-padding {
            width: 100%;
            padding: 20px 0;
        }

        @media (min-width: 768px) {
            .no-x-padding {
                padding: 10px 0;
            }
        }

        .no-margin {
            margin: 0;
        }

        .no-border {
            border: none;
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <h3 class="title
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${this.textContent}
            </h3>
        `;
	}
}
customElements.define("title-comp", TitleComponent);
