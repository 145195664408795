class SubtitleComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `

        .jakarta.semibold {
            font: 600 16px var(--jakarta-font);
        }

        .jakarta.bold {
            font: 700 19px var(--jakarta-font);
        }

        .montserrat.bold {
            font: 700 16px var(--montserrat-font);
        }

        .montserrat.medium {
            font: 500 16px var(--montserrat-font);
            margin: 0;
        }

        @media (min-width: 1440px) {
            .montserrat.medium {
                font-size: 20px;
            }
        }

        @media (min-width: 1850px) {
            .montserrat.medium {
                font-size: 24px;
            }
        }
        
        .section {
            width: calc(100vw - 120px);
            margin: 0;
            text-align: left;
        }

        @media (min-width: 960px) {
            .section {
                font-size: 50px;
            }
        }

        @media (min-width: 1280px) {
            .section {
                font-size: 75px;
            }
        }

        @media (min-width: 1440px) {
            .section {
                font-size: 80px;
            }
        }

        @media (min-width: 1920px) {
            .section {
                font-size: 120px;
            }
        }
        
        .card {
            font: 400 16px var(--aeonik-font);
        }

        .dark {
            color: var(--black-color);
        }

        .light {
            color: var(--white-color);
        }

        .left-sided {
            text-align: left;
        }

        .middle-sided {
            text-align: center;
        }

        .right-sided {
            text-align: right;
        }

        .yellow { 
            color: var(--yellow-color);
        }

        .red { 
            color: var(--red-color);
            padding: 0px 35px;
        }

        .blue { 
            color: var(--blue-color);
            padding: 0px 35px;
        }

        .green { 
            color: var(--green-color);
            padding: 0px 35px;
        }

        .hide-mobile {
            display: none;
        }

        @media (min-width: 768px) {      
            .hide-mobile {
                display: inherit;
            }
            
            .hide-desktop {
                display: none;
            }
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <h4 class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${
				this.hasAttribute("contains-html")
					? this.innerHTML
					: this.textContent
			}
            </h4>
        `;
	}
}
customElements.define("subtitle-comp", SubtitleComponent);
