const en_oilCombustiblesLogoPage = require("url:../../../../assets/oil-combustibles.svg");
const en_heroImageOilCombustibles = require("url:../../../../assets/images/oil-bg.png");
const en_heroImageDesktopOilCombustibles = require("url:../../../../assets/images/oil-bg.png");
const en_rotateIconOilCombustibles = require("url:../../../../assets/icons/rotation-icon.svg");

customElements.define(
	"en-oil-combustibles-page",
	class initOilCombustiblesPage extends HTMLElement {
		connectedCallback() {
			this.render();
			this.addListeners();
		}

		render() {
			this.innerHTML = `
            <div class="container">
                <en-company-header-comp class="header"></en-company-header-comp>
            
				<div id="hero" class="hero">
                    <div class="hero__company-info">
						<div class="info__wrapper">
							<img class="info__company-logo" src="${en_oilCombustiblesLogoPage}">
						</div>
                        <title-comp variant="hero company-slogan jakarta thick-line medium bold" scheme="light">
						Comprehensive services for the oil industry. 
                        </title-comp>
					</div>
					<a class="hero__btn-link" href="https://oilms.ar/" target="_blank">
						<button class="btn hero__btn">
							Visit website
						</button>
					</a>
				</div>
				
				<div id="about-us" class="about-us">
                	<span class="divider mobile"></span>
                    <div class="about-us__content mobile">
                        <subtitle-comp class="hidden" variant="section jakarta bold" scheme="light">About Oil M&S</subtitle-comp>
                        <text-comp class="hidden" variant="section" scheme="light" contains-html>
						Oil M&S provides comprehensive solutions to the hydrocarbon and mining industries throughout the country. Founded in 2001 and, under an integrated management system, the company offers a wide range of services, including oilfield operation and maintenance, integrated oil and gas treatment plant operation, complete environmental management, drilling and workover, civil and engineering works, logistics services and road equipment, among others. The company operates in the provinces of Buenos Aires, Chubut, Neuquén, Río Negro, Santa Cruz and Mendoza.
						<br><br>
						The company provides services by highly qualified employees together with state-of-the-art technological resources to achieve maximum efficiency.
						</text-comp>
					</div>

					<span class="divider bottom mobile"></span>

					<div class="about-us__content desktop">
						<div class="about-container">
                		<div class="about__side-bar">
                		    <div class="side-bar__faq">
                		        <h3 class="side-bar__title yellow active" item="first">Acerca de Oil M&S</h3>
                		    </div>
                		    <div class="side-bar__content">
                		        <h3 class="content__title">
								Comprehensive solutions for the hydrocarbon and mining industries.
                		        </h3>
                		    </div>
                		</div>
                			<div class="about__content">
                			    <div class="content-item first active">
                			        <text-comp variant="about company-hero" scheme="f-light" contains-html>
									Oil M&S provides comprehensive solutions to the hydrocarbon and mining industries throughout the country. Founded in 2001 and, under an integrated management system, the company offers a wide range of services, including oilfield operation and maintenance, integrated oil and gas treatment plant operation, complete environmental management, drilling and workover, civil and engineering works, logistics services and road equipment, among others. The company operates in the provinces of Buenos Aires, Chubut, Neuquén, Río Negro, Santa Cruz and Mendoza.
									<br><br>
									The company provides services by highly qualified employees together with state-of-the-art technological resources to achieve maximum efficiency.
									</text-comp>
                			    </div>
                			</div>
            			</div>
					</div>

					<div style="display: none" class="about-us__media hidden">
						<div class="media__facts-container">
							<div class="facts__item">
								<h3 class="item__number red">20.000</h3>
								<p class="item__text">Linear km covered</p>
							</div>
							<div class="facts__item">
								<h3 class="item__number blue">500</h3>
								<p class="item__text">Projects implemented</p>
							</div>
							<div class="facts__item last">
								<h3 class="item__number green">1200</h3>
								<p class="item__text">Employees</p>
							</div>
							<span class="divider bottom mobile"></span>
						</div>
	
						<div class="media__video-container">
							<img class="hidden media__icon" src="${en_rotateIconOilCombustibles}">
						</div>
	
						<button class="btn bottom__btn">
							<a class="hero__btn-link" href="https://oilms.ar/" target="_blank">Visit website</a>
						</button>
					</div>
				</div>
				<en-footer-comp></footer-comp>
			</div>
        	`;

			const style = document.createElement("style");
			style.textContent = `

			.hidden {
				opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
			}

			@media (prefers-reduced-motion) {
				.hidden {
					transition: none;
				}
			}

			.show {
				opacity: 1;
				filter: blur(0);
				transform: translateY(0);
			}

            .divider {
                margin-top: 3.8vh;
                width: 8vh;
                height: 1px;
                transform: rotate(90deg);
                border: 1px solid var(--white-color);
            }

			.divider.dark {
				border-color: var(--dark-color);
			}

            .divider.xl {
                width: 75px;
            }

            .divider.bottom {
                margin-top: 0;
                margin-bottom: 3.8vh;
				align-self: center;
            }

			@media (min-width: 768px) {
				.divider {
					display: none;
				}
			}
			
			@media (min-width: 630px) {
				.divider.mobile {
					display: none;
				}
			}

            .container {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				user-select: none;
            }

			.hero {
                width: 100%;
                height: 100vh;
				padding: 0 50px;
				padding-top: 25vh;
				padding-bottom: 10vh;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                background-image: url(${en_heroImageOilCombustibles});
                background-position: left center;
				background-size: cover;
            }

			@media (min-width: 500px) {
				.hero {
					justify-content: space-between;
					align-items: flex-start;
					background-image: url(${en_heroImageDesktopOilCombustibles});
					background-position: bottom center;
					background-size: cover;
				}
			}
			
			@media (min-width: 768px) {
				.hero {
					height: 100vh;
				}
			}

			@media (min-width: 1440px) {
				.hero {
					padding: 0 100px;
					padding-top: 25vh;
					padding-bottom: 10vh;
				}
			}

            .hero__company-info {
				max-width: 275px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
				gap: 40px;
            }

			@media (min-width: 500px) {
				.hero__company-info {
					max-width: none;
				}
			}

			.info__wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
			}

			.info__company-logo {
				width: 205px;
			}

			@media (min-width: 960px) {
				.info__company-logo {
					width: 270px;
				}	
			}

			@media (min-width: 1280px) {
				.info__company-logo {
					width: 300px;
				}	
			}

			@media (min-width: 1440px) {
				.info__company-logo {
					width: 320px;
				}	
			}

			@media (min-width: 1850px) {
				.info__company-logo {
					width: 400px;
				}	
			}

			.about-container {
                height: 70vh;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid var(--white-color);
            }

			@media (min-width: 768px) {
				.about-container {
					height: 80vh;
				}
			}

			@media (min-width: 1280px) {
				.about-container {
					height: 90vh;
				}
			}

            .about__side-bar {
                width: 40%;
                height: 100%;
                padding: 0 25px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
				gap: 10px;
                border-right: 1px solid var(--white-color);
            }

            @media (min-width: 768px) {
                .about__side-bar {
                    height: 100%;
                    padding: 0 50px;
                }
            }

            @media (min-width: 1280px) {
                .about__side-bar {
                    width: 35%;
                }
            }

            @media (min-width: 1440px) {
                .about__side-bar {
                    width: 40%;
                    height: 100%;
                    padding: 0 100px;
                }
            }

            @media (min-width: 1850px) {
                .about__side-bar {
                    height: 100%;
                }
            }

            .side-bar__faq {
                width: 100%;
            }

            .side-bar__title {
                width: 100%;
                margin: 10px 0;
                font: 700 20px var(--jakarta-font);
                color: var(--white-color);
                text-align: left;
                opacity: .25;
                transition: all .4s;
            }
            
            @media (min-width: 960px) {
                .side-bar__title {
                    width: 100%;
                    margin: 10px 0;
                    font-size: 27px;
                }
            }

            @media (min-width: 1280px) {
                .side-bar__title {
                    font-size: 30px;
                }
            }

            @media (min-width: 1440px) {
                .side-bar__title {
                    font-size: 35px;
                }
            }

            @media (min-width: 1850px) {
                .side-bar__title {
                    font-size: 47px;
                }
            }

            .side-bar__title.active {
                opacity: 1;
            }

            .side-bar__title.active.yellow {
                color: var(--yellow-color);
            }

            .side-bar__title.active.red {
                color: var(--red-color);
            }

            .side-bar__title.active.blue {
                color: var(--blue-color);
            }

            .side-bar__title.active.green {
                color: var(--green-color);
            }

            .side-bar__content {
                width: 100%;
            }

            .content__title {
                font: 700 16px var(--jakarta-font);
                color: var(--white-color);
            }

            @media (min-width: 960px) {
                .content__title {
                    font-size: 19px;
                }
            }

            @media (min-width: 1280px) {
                .content__title {
                    font-size: 23px;
                }
            }

            @media (min-width: 1440px) {
                .content__title {
                    font-size: 22px;
                }
            }

            @media (min-width: 1850px) {
                .content__title {
                    margin: 0;
                    font-size: 33px;
                }
            }

            .content__text {
                width: 85%;
                font: 500 12px var(--jakarta-font); 
                color: var(--white-color);
            }

            @media (min-width: 960px) {
                .content__text {
                    font-size: 14px;
                }
            }

            @media (min-width: 1280px) {
                .content__text {
                    font-size: 16px;
                }
            }

            @media (min-width: 1440px) {
                .content__text {
                    font-size: 20px;
                }
            }

            @media (min-width: 1850px) {
                .content__text {
                    font-size: 24px;
                }
            }

            .about__content {
                width: 60%;
                height: 100%;
                padding: 0 25px;
				display: flex;
				justify-content: center;
				align-items: center;
            }

            @media (min-width: 768px) {
                .about__content {
                    height: 100%;
                    padding: 0 50px;
                }
            }

			@media (min-width: 1280px) {
                .about__content {
					width: 64%;
                    height: 100%;
                    padding: 0 50px;
                }
            }

            @media (min-width: 1440px) {
                .about__content {
                    padding: 0 100px;
                }
            }

            @media (min-width: 1850px) {
                .about__content {
                    height: 100%;
                }
            }

            .content-item {
                max-width: 300px;
                position: absolute;
                opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
            }
            
            @media (min-width: 768px) {
                .content-item {
                    max-width: 325px;
                }
            }

            @media (min-width: 960px) {
                .content-item {
                    max-width: 450px;
                }
            }

            @media (min-width: 1280px) {
                .content-item {
                    max-width: 670px;
                }
            }

            @media (min-width: 1440px) {
                .content-item {
                    max-width: 600px;
                }
            }

            @media (min-width: 1850px) {
                .content-item {
                    max-width: 840px;
                }
            }

            .content-item.active {
                opacity: 1;
                filter: blur(0);
				transform: translateY(0);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

			.about-us {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
				background-color: var(--bkgd-color);
                border-top: 1px solid var(--white-color);
                border-bottom: 1px solid var(--white-color);
            }

			@media (min-width: 630px) {
				.about-us {
					border-top: none;
				}
			}

			.about-us__content {
				width: 100%;
				height: 100%;
				padding: 10vh 25px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.about-us__content.second-section {
				border-bottom: 1px solid var(--white-color);
			}

			@media (min-width: 630px) {
				.about-us__content {
					padding: 0;
					padding-top: 9vh;
					border-bottom: 1px solid var(--white-color)
				}
			}

			.about-us__content.desktop {
				display: none;
			}

			@media (min-width: 630px) {
				.about-us__content.mobile {
					display: none;
				}
				
				.about-us__content.desktop {
					display: inherit;
				}
			}

			.about-us__media {
				width: 100%;
				height: 100%;
				padding: 50px 0;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 15px;
				background-color: var(--white-color);
			}

			@media (min-width: 1850px) {
				.about-us__media {
					padding: 100px 0;
				}
			}

			.media__facts-container {
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 50px;
				border-bottom: 1px solid var(--primary-color);
			}

			@media (min-width: 768px) {
				.media__facts-container {
					width: 60%;
					border-bottom: none;
				}
			}

			@media (min-width: 960px) {
				.media__facts-container {
					flex-direction: row;
					align-items: flex-start;
					border-bottom: none;
				}
			}

			@media (min-width: 1280px) {
				.media__facts-container {
					width: 50%;
				}
			}

			@media (min-width: 1850px) {
				.media__facts-container {
					width: 55%;
				}
			}

			.facts__item {
				width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
			}

			.facts__item.last {
				padding-bottom: 7vh;
			}

			@media (min-width: 960px) {
				.facts__item.last {
					padding-bottom: 0;
				}
			}

			.item__number {
				font: 700 49px var(--jakarta-font);
				line-height: 68px;
				text-align: center;
				color: var(--primary-color);
			}

			@media (min-width: 1280px) {
				.item__number {
					font-size: 65px;
				}
			}

			@media (min-width: 1440px) {
				.item__number {
					font-size: 80px;
				}
			}

			@media (min-width: 1850px) {
				.item__number {
					font-size: 95px;
				}
			}

			.item__text {
				width: 70%;
				font: 700 15px var(--jakarta-font);
				line-height: 22px;
				text-align: center;
				color: var(--primary-color);
			}

			@media (min-width: 425px) {
				.item__text {
					width: 50%;
				}
			}

			@media (min-width: 600px) {
				.item__text {
					width: 45%;
				}
			}

			@media (min-width: 768px) {
				.item__text {
					width: 65%;
					font-weight: 400;
					font-size: 20px;
				}
			}

			@media (min-width: 960px) {
				.item__text {
					width: 80%;
					margin-top: 15px;
				}
			}

			@media (min-width: 1440px) {
				.item__text {
					width: 75%;
					font-size: 22px;
					margin-top: 20px;
				}
			}

			@media (min-width: 1850px) {
				.item__text {
					width: 70%;
					font-size: 33px;
					line-height: 1.2;
					margin-top: 30x;
				}
			}

			.media__video-container {
				width: 100%;
				height: 100%;
				padding: 0px 10px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 15px;
			}

			@media (min-width: 768px) {				
				.media__video-container {
					height: 65vh;
					padding: 50px 0;
				}
			}

			@media (min-width: 960px) {
				.media__video-container {
					height: 80vh;
					padding-top: 0;
				}
			}

			@media (min-width: 1280px) {
				.media__video-container {
					height: 90vh;
					padding: 60px 0;
					padding-top: 0;
				}
			}

			@media (min-width: 1440px) {
				.media__video-container {
					padding: 100px 0;
					padding-top: 0;
				}
			}

			@media (min-width: 1850px) {
				.media__video-container {
					padding: 110px 0;
					padding-top: 0;
				}
			}

			.media__icon {
				mix-blend-mode: difference;
			}

			@media (min-width: 768px) {
				.media__icon {
					display: none;
				}
			}

			.btn {
                width: 95px;
                padding: 9px 19px;
                text-align: center;
                border: none;
                border-radius: 50px;
                font: 400 11px var(--roboto-font);
				transition: all .4s;
			}

			.btn:hover {
				cursor: pointer;
				opacity: .7;
			}

			.hero__btn {
				color: var(--primary-color);
                background-color: var(--white-color);
			}

			@media (min-width: 500px) {
				.hero__btn {
					align-self: center;
				}
			}

			@media (min-width: 768px) {
				.hero__btn {
					width: 120px;
					height: 40px;
					font-size: 14px;
				}
			}

			@media (min-width: 1280px) {
				.hero__btn {
					width: 140;
					height: 50px;
					font-size: 16px;
				}
			}

			@media (min-width: 1440px) {
				.hero__btn {
					width: 180px;
					height: 65px;
					font-size: 21px;
				}
			}

			.hero__btn-link {
				color: var(--dark-color);
				text-decoration: none;
				align-self: center;
			}

			.bottom__btn {
				margin-top: 50px;
				margin-bottom: 25px;
				color: var(--text-color);
                background-color: var(--primary-color);
			}

			@media (min-width: 768px) {
				.bottom__btn {
					width: 120px;
					height: 40px;
					font-size: 14px;
				}
			}

			@media (min-width: 960px) {
				.bottom__btn {
					margin-top: 0;
				}
			}

			@media (min-width: 1280px) {
				.bottom__btn {
					width: 140;
					height: 50px;
					font-size: 16px;
				}
			}

			@media (min-width: 1440px) {
				.bottom__btn {
					width: 180px;
					height: 65px;
					font-size: 21px;
				}
			}

			.red {
				color: var(--origin-red-color);
			}

			.blue {
				color: var(--origin-blue-color);
			}

			.green {
				color: var(--origin-green-color);
			}
		`;

			this.appendChild(style);
		}

		addListeners() {
			const bodyEl = document.querySelector("body");

			window.addEventListener("load", () => {
				bodyEl.style.overflowY = "auto";
			});

			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						entry.target.classList.add("show");
					} else {
						entry.target.classList.remove("show");
					}
				});
			});

			const hiddenElements = this.querySelectorAll(".hidden");
			hiddenElements.forEach((el) => observer.observe(el));
		}
	}
);
