const en_indaloLogo = require("url:../../../assets/indalo-logo.svg");
const en_burgerMenu = require("url:../../../assets/icons/burger-menu.svg");
const en_crossIcon = require("url:../../../assets/icons/cross-icon.svg");

class en_HeaderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        .header {
            z-index: 200;
            position: fixed;
            left: 0;
            right: 0;
            width: calc(100% - 50px);
            padding: 0 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--menu-bkgd-color);
            backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			border-bottom: 1px solid #fff;
			transition: all .4s linear;
        }
        
			.header__right {
				display: none
			}
			
			@media (min-width: 768px) {
					.header {
						width: calc(100% - 100px);
						padding: 0 50px;
					}
			}
			@media (max-width: 767px) {
				.header {
					gap: 12px;
					justify-content: flex-start;
				}
				.header__right {
					display: flex;
					width: 100%;
					justify-content: space-between;
				}
			}
		

		@media (min-width: 1280px) {
			.header {
				height: 90px;
			}
		}

		@media (min-width: 1440px) {
            .header {
				width: calc(100% - 200px);
				padding: 0 100px;
            }
        }

		.header__companies {
			margin: 0 auto;
			font: 700 18px var(--jakarta-font);
			text-decoration: none;
			color: var(--white-color);
		}

		.header__companies:hover {
			cursor: pointer;
		}

		.companies__list {
			display: none;
			transition: all .4s linear;
		}

		.companies__list.active {
			position: absolute;
			width: 150px;
			height: 200px;
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			background-color: var(--menu-bkgd-color);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
			border: 1px solid #fff;
			transition: all .4s linear;
		}

		@media (min-width: 768px) {
			.companies__list.active {
				top: 60px;
				right: 280px;
			}
		}

		@media (min-width: 1280px) {
			.companies__list.active {
				top: 90px;
			}
		}


		@media (min-width: 1440px) {
			.companies__list.active {
				top: 90px;
				right: 330px;
			}
		}

		.indalo-logo__wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		.indalo-logo {
            padding: 15px 0;
            padding-right: 25px;
			height: 20px;
		}

		@media (min-width: 768px) {
			.indalo-logo {
				padding-right: 50px;
				height: 30px;
			}
		}

		@media (min-width: 1440px) {
			.indalo-logo {
				padding-right: 100px;
				height: 30px;
			}
		}

		@media (min-width: 1850px) {
			.indalo-logo {
				height: 40px;
			}
		}

        .header .indalo-logo__wrapper {
            border-right: 1px solid #fff;
        }

		.indalo-logo:hover {
			cursor: pointer;
		}
		
		.header-nav {
			display: none;
		}

		@media (min-width: 769px) {
			.header-nav {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 34px;
			}	
		}

		.burger-menu:hover {
			cursor: pointer;
		}

		.cross-menu {
			display: none;
		}

		.cross-menu:hover {
			cursor: pointer;
		}

		@media (min-width: 769px) {
			.menu,
			.burger-menu,
			.cross-menu {
				display: none;
			}
		}
		
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
                <header class="header">
                    <a class="indalo-logo__wrapper" href="/en#hero"><img class="indalo-logo" src="${en_indaloLogo}"></a>
                    <div class="header__right">
											<translate-comp class="translate--mobile"></translate-comp>
											<img class="burger-menu" src="${en_burgerMenu}">
                    	<img class="cross-menu" src="${en_crossIcon}">
										</div>
					<div class="header-nav">
						<link-comp class="header-nav__link" variant="header" scheme="light" href="/en#hero">Home</link-comp>
						<link-comp class="header-nav__link" variant="header" scheme="light" href="/en#about-us">About us</link-comp>
						<a class="header__companies" href="/en#companies">Company</a>
						<div class="companies__list">
							<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/indalo-media">Indalo Media</link-comp>
							<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/oil-m&s">Oil M&S</link-comp>
							<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/cpc">CPC</link-comp>
							<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/providencia">Providencia</link-comp>
							<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/alpat">Alpat</link-comp>
							<link-comp class="nav-link" variant="menu submenu" scheme="light" href="/en/company/serma">Serma</link-comp>
						</div>
						<link-comp class="header-nav__link" variant="header" scheme="light" href="/en#sust">Sustainability</link-comp>
						<translate-comp></translate-comp>
					</div>
                </header>
                <en_menu-comp class="menu"></en_menu-comp>
        `;
	}

	elementsOverlap(el1, el2) {
		const domRect1 = el1.getBoundingClientRect();
		const domRect2 = el2.getBoundingClientRect();

		const overlaps = !(
			domRect1.top > domRect2.bottom || domRect1.bottom < domRect2.top
		);

		return overlaps;
	}

	addListeners() {
		const bodyEl = document.querySelector("body");
		const burgerMenu: HTMLElement =
			this.shadow.querySelector(".burger-menu");
		const crossMenu: HTMLElement = this.shadow.querySelector(".cross-menu");

		const headerEl: HTMLElement = this.shadow.querySelector(".header");

		const menuComponentEl = this.shadow.querySelector(".menu");

		const headerNavLinksEl =
			this.shadow.querySelectorAll(".header-nav__link");

		const headerCompaniesEl =
			this.shadow.querySelector(".header__companies");
		const companiesListEl: HTMLElement =
			this.shadow.querySelector(".companies__list");

		const shadowMenuEl =
			menuComponentEl.shadowRoot.querySelector(".menu-container");
		const shadowMenuLinksEl =
			menuComponentEl.shadowRoot.querySelectorAll(".nav-link");

		const aboutUsDividerEl = document.querySelector(".divider.bottom");
		const mediaSectionEl = document.querySelector(".about-us__media");
		const companiesSectionEl = document.querySelector(".companies");
		const rseSectionEl = document.querySelector(".sust");

		burgerMenu.addEventListener("click", () => {
			bodyEl.style.overflowY = "hidden";
			shadowMenuEl.classList.toggle("active");
			burgerMenu.style.display = "none";
			crossMenu.style.display = "inherit";
		});

		crossMenu.addEventListener("click", () => {
			bodyEl.style.overflowY = "auto";
			shadowMenuEl.classList.toggle("active");
			burgerMenu.style.display = "inherit";
			crossMenu.style.display = "none";
		});

		headerEl.addEventListener("mouseleave", () => {
			companiesListEl.classList.remove("active");
		});

		headerCompaniesEl.addEventListener("mouseover", () => {
			companiesListEl.classList.toggle("active");
		});

		headerNavLinksEl.forEach((link) => {
			link.addEventListener("mouseover", () => {
				companiesListEl.classList.remove("active");
			});
		});

		shadowMenuLinksEl.forEach((link) => {
			if (!link.classList.contains("companies-list")) {
				link.addEventListener("click", () => {
					bodyEl.style.overflowY = "auto";
					shadowMenuEl.classList.toggle("active");
					burgerMenu.style.display = "inherit";
					crossMenu.style.display = "none";
				});
			} else {
				bodyEl.style.overflowY = "auto";
			}
		});

		const mainLandingWindowEvent = () => {
			const headerOverlapsDivider = this.elementsOverlap(
				headerEl,
				aboutUsDividerEl
			);
			const headerOverlapsMediaSection = this.elementsOverlap(
				headerEl,
				mediaSectionEl
			);
			const headerOverlapsCompaniesSection = this.elementsOverlap(
				headerEl,
				companiesSectionEl
			);
			const headerOverlapsRSESection = this.elementsOverlap(
				headerEl,
				rseSectionEl
			);

			if (
				headerOverlapsMediaSection ||
				headerOverlapsCompaniesSection ||
				headerOverlapsRSESection
			) {
				headerEl.style.backdropFilter = "blur(0)";
				headerEl.style.backgroundColor = "#111";
				companiesListEl.style.backdropFilter = "blur(0)";
				companiesListEl.style.backgroundColor = "#111";
			} else if (headerOverlapsDivider) {
				headerEl.style.backdropFilter = "blur(10px)";
				headerEl.style.backgroundColor = "var(--menu-bkgd-color)";
				companiesListEl.style.backdropFilter = "blur(10px)";
				companiesListEl.style.backgroundColor =
					"var(--menu-bkgd-color)";
			}

			const scrollTop = document.documentElement.scrollTop;
			if (scrollTop < window.innerHeight) {
				headerEl.style.backdropFilter = "blur(10px)";
				headerEl.style.backgroundColor = "var(--menu-bkgd-color)";
				companiesListEl.style.backdropFilter = "blur(10px)";
				companiesListEl.style.backgroundColor =
					"var(--menu-bkgd-color)";
			}
		};

		window.addEventListener("scroll", mainLandingWindowEvent);
	}
}

customElements.define("en-header-comp", en_HeaderComponent);
