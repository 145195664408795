class en_AboutComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
            .about-container {
                height: 90vh;
                width: 100vw;
                display: flex;
                justify-content: space-between;
                align-items: center;

            }

            .about__side-bar {
                width: 40%;
                height: calc(100% - 25px);
                padding: 0 25px;
                padding-top: 25px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                border-right: 1px solid var(--white-color);
                border-top: 1px solid var(--white-color);
            }

            @media (min-width: 768px) {
                .about__side-bar {
                    height: calc(100% - 50px);
                    padding: 0 50px;
                    padding-top: 50px;
                }
            }

            @media (min-width: 960px) {
                .about__side-bar {
                    width: 38%;
                }
            }

            @media (min-width: 1280px) {
                .about__side-bar {
                    width: 30%;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .about__side-bar {
                    height: calc(100% - 25px);
                    padding-top: 25px;
                }
            }


            @media (min-width: 1440px) {
                .about__side-bar {
                    width: 42%;
                    height: calc(100% - 50px);
                    padding: 0 100px;
                    padding-top: 50px;
                }
            }

            @media (min-width: 1850px) {
                .about__side-bar {
                    width: 39%;
                    height: calc(100% - 100px);
                    padding-top: 100px;
                }
            }

            .side-bar__faq {
                width: 100%;
            }

            .side-bar__title {
                width: 100%;
                margin: 10px 0;
                font: 700 19px var(--jakarta-font);
                color: var(--white-color);
                text-align: left;
                opacity: .25;
                transition: all .4s;
            }
            
            .side-bar__title:hover {
                cursor: pointer;
            }

            @media (min-width: 960px) {
                .side-bar__title {
                    width: 100%;
                    margin: 10px 0;
                    font-size: 24px;
                }
            }

            @media (min-width: 1280px) {
                .side-bar__title {
                    margin: 27px 0;
                    font-size: 30px;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .side-bar__title {
                    font-size: 25px;
                }
            }

            @media (min-width: 1440px) {
                .side-bar__title {
                    margin: 27px 0;
                    font-size: 32px;
                }
            }

            @media (min-width: 1850px) {
                .side-bar__title {
                    margin: 27px 0;
                    font-size: 47px;
                }
            }

            .side-bar__title.active {
                opacity: 1;
            }

            .side-bar__title.active.yellow {
                color: var(--yellow-color);
            }

            .side-bar__title.active.red {
                color: var(--red-color);
            }

            .side-bar__title.active.blue {
                color: var(--blue-color);
            }

            .side-bar__title.active.green {
                color: var(--green-color);
            }

            .side-bar__content {
                width: 100%;
            }

            @media (min-width: 1850px) {
                .side-bar__content {
                    margin-top: 55px; 
                }
            }

            .content__title {
                font: 700 22px var(--jakarta-font);
                color: var(--white-color);
            }

            @media (min-width: 960px) {
                .content__title {
                    font-size: 30px;
                }
            }

            @media (min-width: 1280px) {
                .content__title {
                    font-size: 33px;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .content__title {
                    font-size: 27px;
                }
            }


            @media (min-width: 1440px) {
                .content__title {
                    font-size: 35px;
                }
            }

            @media (min-width: 1850px) {
                .content__title {
                    margin: 0;
                    font-size: 54px;
                }
            }

            .content__text {
                width: 85%;
                font: 500 12px var(--jakarta-font); 
                color: var(--white-color);
            }

            @media (min-width: 960px) {
                .content__text {
                    font-size: 14px;
                }
            }

            @media (min-width: 1280px) {
                .content__text {
                    font-size: 16px;
                }
            }

            @media (min-width: 1280px) and (max-height: 720px) {
                .content__text {
                    font-size: 14px;
                }
            }


            @media (min-width: 1440px) {
                .content__text {
                    font-size: 18px;
                }
            }

            @media (min-width: 1850px) {
                .content__text {
                    font-size: 24px;
                }
            }

            .about__content {
                width: 55%;
                height: 100%;
                padding: 0 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-top: 1px solid var(--white-color);
            }

            @media (min-width: 768px) {
                .about__content {
                    height: 100%;
                    padding: 0 50px;
                }
            }

            @media (min-width: 960px) {
                .about__content {
                    width: 60%;
                }
            }

            @media (min-width: 1440px) {
                .about__content {
                    padding: 0 100px;
                }
            }

            @media (min-width: 1850px) {
                .about__content {
                    height: 100%;
                }
            }

            .content-item {
                max-width: 300px;
                position: absolute;
                opacity: 0;
				filter: blur(5px);
				transform: translateY(-50px);
				transition: all .6s;
            }
            
            @media (min-width: 768px) {
                .content-item {
                    max-width: 325px;
                }
            }

            @media (min-width: 960px) {
                .content-item {
                    max-width: 450px;
                }
            }

            @media (min-width: 1280px) {
                .content-item {
                    max-width: 670px;
                }
            }

            @media (min-width: 1440px) {
                .content-item {
                    max-width: 600px;
                }
            }

            @media (min-width: 1850px) {
                .content-item {
                    max-width: 840px;
                }
            }

            .content-item.active {
                opacity: 1;
                filter: blur(0);
				transform: translateY(0);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
            <div class="about-container">
                <div class="about__side-bar">
                    <div class="side-bar__faq">
                        <h3 class="side-bar__title yellow active" item="first">What is Grupo Indalo?</h3>
                        <h3 class="side-bar__title red" item="second">Mission</h3>
                        <h3 class="side-bar__title blue" item="third">Vision</h3>
                        <h3 class="side-bar__title green" item="fourth">Values</h3>
                    </div>
                    <div class="side-bar__content">
                        <h3 class="content__title">
                            Our future,
                            <br>
                            much to do.
                        </h3>
                        <p class="content__text">
                            It was established in 1999 and since then it has become one of the most prominent groups in Argentina with growth projections across the region.
                        </p>
                    </div>
                </div>
                <div class="about__content">
                    <div class="content-item first active">
                        <text-comp variant="about" scheme="f-light">
                        Grupo Indalo is a conglomerate of companies that has been investing in Argentina for more than twenty years and is committed to the development of the country, generating employment for thousands of workers throughout the country. 
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                        Grupo Indalo includes production and service companies in different industries, mainly in media, construction, energy and mining.
                        </text-comp>
                    </div>
                    <div class="content-item second">
                        <text-comp variant="about" scheme="f-light">
                            Our mission is to contribute to the economic and social development of Argentina by investing in companies in different industries, generating employment and improving the quality of life of the population.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                        We aim to be leaders in each of our business areas, providing high quality products and services that meet the needs of our customers and generate a positive impact on society.
                        </text-comp>
                    </div>
                    <div class="content-item third">
                        <text-comp variant="about" scheme="f-light">
                        We aim to consolidate the leadership of our business group in the country and to expand into the region in search of new strategic markets.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                        To be a key player in each of the industries in which we operate, providing innovative products and solutions that generate value for our customers and society in general.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light">
                        In addition, we are committed to being a socially responsible company, promoting sustainability and environmental protection in each of our operations and contributing to the development of the communities in which we operate.
                        </text-comp>
                    </div>
                    <div class="content-item fourth">
                        <text-comp variant="about" scheme="f-light" contains-html>
                            <b>Commitment:</b>
                            We are committed to providing innovative and quality products to our customers and to being a socially responsible group of companies that contributes to the sustainable development of the communities in which we operate.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light" contains-html>
                            <b>Innovation:</b>
                            We strive to be at the forefront of each of the markets in which we operate, investing in new technologies and fostering creativity and innovation in all our work teams.
                        </text-comp>
                        <text-comp variant="about" scheme="f-light" contains-html>
                            <b>Excellence:</b>
                            We pursue excellence in everything we do, from business management to the quality of our products and services.
                        </text-comp>
                    </div>
                </div>
            </div>
        `;
	}

	addListeners() {
		const sideBarTitles = this.shadow.querySelectorAll(".side-bar__title");

		const firstTitleEl = this.shadow.querySelector("[item=first]");
		const secondTitleEl = this.shadow.querySelector("[item=second]");
		const thirdTitleEl = this.shadow.querySelector("[item=third]");
		const fourthTitleEl = this.shadow.querySelector("[item=fourth]");

		const firstContentEl = this.shadow.querySelector(".content-item.first");
		const secondContentEl = this.shadow.querySelector(
			".content-item.second"
		);
		const thirdContentEl = this.shadow.querySelector(".content-item.third");
		const fourthContentEl = this.shadow.querySelector(
			".content-item.fourth"
		);

		sideBarTitles.forEach((title) => {
			title.addEventListener("click", () => {
				const itemAttr = title.getAttribute("item");

				if (itemAttr == "first") {
					firstTitleEl.classList.add("active");
					firstContentEl.classList.add("active");

					secondTitleEl.classList.remove("active");
					thirdTitleEl.classList.remove("active");
					fourthTitleEl.classList.remove("active");

					secondContentEl.classList.remove("active");
					thirdContentEl.classList.remove("active");
					fourthContentEl.classList.remove("active");
				} else if (itemAttr == "second") {
					secondTitleEl.classList.add("active");
					secondContentEl.classList.add("active");

					firstTitleEl.classList.remove("active");
					thirdTitleEl.classList.remove("active");
					fourthTitleEl.classList.remove("active");

					firstContentEl.classList.remove("active");
					thirdContentEl.classList.remove("active");
					fourthContentEl.classList.remove("active");
				} else if (itemAttr == "third") {
					thirdTitleEl.classList.add("active");
					thirdContentEl.classList.add("active");

					firstTitleEl.classList.remove("active");
					secondTitleEl.classList.remove("active");
					fourthTitleEl.classList.remove("active");

					firstContentEl.classList.remove("active");
					secondContentEl.classList.remove("active");
					fourthContentEl.classList.remove("active");
				} else if (itemAttr == "fourth") {
					fourthTitleEl.classList.add("active");
					fourthContentEl.classList.add("active");

					firstTitleEl.classList.remove("active");
					secondTitleEl.classList.remove("active");
					thirdTitleEl.classList.remove("active");

					firstContentEl.classList.remove("active");
					secondContentEl.classList.remove("active");
					thirdContentEl.classList.remove("active");
				}
			});
		});
	}
}

customElements.define("en-about-content", en_AboutComponent);
