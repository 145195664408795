const leftArrow = require("url:../../assets/icons/left-arrow.svg");
const rightArrow = require("url:../../assets/icons/right-arrow.svg");

class TextSliderComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		const style = document.createElement("style");

		style.textContent = `
        
        .carousel-container {
            width: calc(100% - 70px);
            height: 100%;
            margin: 10px auto;
            margin-bottom: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
        }

        .carousel-container.last-item {
            margin-bottom: 70px;
        }

        .carousel {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;
        }

        @media (min-width: 630px) {
            .carousel {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 65px 0;
                overflow: hidden;
            }
        }

        .sliders-container {
            width: 300%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            transition: all .7s ease;
            overflow: hidden;
            scroll-snap-type: x mandatory;
			scroll-behavior: smooth;
        }
        
        .sliders-container.dual-slider {
            width: 200%;
        }

        .sliders__item {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            vertical-align: top;
            scroll-snap-align: center;
        }
        
        .sliders__item.hidden {
            display: none;
        }
        
        .item:hover {
            cursor: grab;
        }

        .item__controlls {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 35px;
        }

        .left-arrow, 
        .right-arrow {
            opacity: 0.7;
        }

        .left-arrow:hover, 
        .right-arrow:hover {
            cursor: pointer;
            opacity: 1;
        }
        `;

		this.render();

		this.shadow.appendChild(style);
	}

	addListeners() {
		const carouselEl: any = this.shadow.querySelector(".carousel");
		const SlContainerEl: any =
			this.shadow.querySelector(".sliders-container");

		const slidersItemsEl = this.shadow.querySelectorAll(".sliders__item");

		const leftArrowEl: any = this.shadow.querySelector(".left-arrow");
		const rightArrowEl: any = this.shadow.querySelector(".right-arrow");

		let isDragging = false,
			startPos = 0,
			currentTranslate = 0,
			prevTranslate = 0,
			animationID,
			currentIndex = 0;

		slidersItemsEl.forEach((slide, index) => {
			slide.addEventListener("touchstart", touchStart(index));
			slide.addEventListener("mousedown", touchStart(index));

			if (SlContainerEl.classList.contains("dual-slider")) {
				slide.addEventListener("touchend", dualTouchEnd);
				slide.addEventListener("mouseup", dualTouchEnd);
			} else {
				slide.addEventListener("touchend", touchEnd);
				slide.addEventListener("mouseup", touchEnd);
			}

			slide.addEventListener("touchmove", touchMove);
			slide.addEventListener("mousemove", touchMove);
		});

		function getPositionX(event) {
			return event.type.includes("mouse")
				? event.pageX
				: event.touches[0].clientX;
		}

		function touchStart(index) {
			return function (event) {
				currentIndex = index;
				startPos = getPositionX(event);
				isDragging = true;
				animationID = requestAnimationFrame(animation);
			};
		}

		function touchMove(event) {
			if (isDragging) {
				const currentPosition = getPositionX(event);
				currentTranslate = prevTranslate + currentPosition - startPos;
			}
		}

		function dualTouchEnd() {
			cancelAnimationFrame(animationID);
			isDragging = false;

			const offset = SlContainerEl.getAttribute("offset");
			const movedBy = currentTranslate - prevTranslate;

			// if moved enough negative then snap to next slide if there is one
			if (movedBy < -100 && offset == "0") {
				SlContainerEl.style.transform = "translateX(-50%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (movedBy < -100 && offset == "1") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}

			// if moved enough positive then snap to previous slide if there is one
			if (movedBy > 100 && offset == "0") {
				SlContainerEl.style.transform = "translateX(-50%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (movedBy > 100 && offset == "1") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}
		}

		function touchEnd() {
			cancelAnimationFrame(animationID);
			isDragging = false;

			const offset = SlContainerEl.getAttribute("offset");
			const movedBy = currentTranslate - prevTranslate;

			// if moved enough negative then snap to next slide if there is one
			if (movedBy < -100 && offset == "0") {
				SlContainerEl.style.transform = "translateX(-33.3%)";
				SlContainerEl.setAttribute("offset", "1");
			} else if (movedBy < -100 && offset == "1") {
				SlContainerEl.style.transform = "translateX(-66.6%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (movedBy < -100 && offset == "2") {
				SlContainerEl.style.transform = "translateX(0%)";
				SlContainerEl.setAttribute("offset", "0");
			}

			// if moved enough positive then snap to previous slide if there is one

			if (movedBy > 100 && offset == "0") {
				SlContainerEl.style.transform = "translateX(-66.6%)";
				SlContainerEl.setAttribute("offset", "2");
			} else if (movedBy > 100 && offset == "1") {
				SlContainerEl.style.transform = "translateX(-0%)";
				SlContainerEl.setAttribute("offset", "0");
			} else if (movedBy > 100 && offset == "2") {
				SlContainerEl.style.transform = "translateX(-33.3%)";
				SlContainerEl.setAttribute("offset", "1");
			}
		}

		function animation() {
			if (isDragging) requestAnimationFrame(animation);
		}

		if (SlContainerEl.classList.contains("dual-slider")) {
			leftArrowEl.addEventListener("click", (e) => {
				const target = e.target as any;
				const position = target.getAttribute("position");
				const offset = SlContainerEl.getAttribute("offset");

				if (position == "previous" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-50%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "previous" && offset == "1") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			});

			rightArrowEl.addEventListener("click", (e) => {
				const target = e.target as any;
				const position = target.getAttribute("position");
				const offset = SlContainerEl.getAttribute("offset");

				if (position == "next" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-50%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "next" && offset == "1") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			});
		} else {
			leftArrowEl.addEventListener("click", (e) => {
				const target = e.target as any;
				const position = target.getAttribute("position");
				const offset = SlContainerEl.getAttribute("offset");

				if (position == "previous" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-66.6%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "previous" && offset == "1") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "0");
				} else if (position == "previous" && offset == "2") {
					SlContainerEl.style.transform = "translateX(-33.3%)";
					SlContainerEl.setAttribute("offset", "1");
				}
			});

			rightArrowEl.addEventListener("click", (e) => {
				const target = e.target as any;
				const position = target.getAttribute("position");
				const offset = SlContainerEl.getAttribute("offset");

				if (position == "next" && offset == "0") {
					SlContainerEl.style.transform = "translateX(-33.3%)";
					SlContainerEl.setAttribute("offset", "1");
				} else if (position == "next" && offset == "1") {
					SlContainerEl.style.transform = "translateX(-66.6%)";
					SlContainerEl.setAttribute("offset", "2");
				} else if (position == "next" && offset == "2") {
					SlContainerEl.style.transform = "translateX(0%)";
					SlContainerEl.setAttribute("offset", "0");
				}
			});
		}
	}

	render() {
		this.shadow.innerHTML = `
        <div class="carousel-container ${this.getAttribute("variant")}">
            <div class="carousel">
                <div class="sliders-container ${
					this.hasAttribute("dualSlider") ? "dual-slider" : ""
				}" offset="0">
                    <div class="sliders__item">
                        <text-comp class="item" variant="slider no-margin left-sided" scheme="light" contains-html>
                        ${
							this.hasAttribute("first-title")
								? `
                                <b class="regular f-light">
                                    ${this.getAttribute("first-title")}
                                </b>
                                <br>
                                `
								: ""
						}
                        ${this.getAttribute("first-paragraph")} 
                        </text-comp>
                    </div>
                    <div class="sliders__item">
                        <text-comp class="item" variant="slider no-margin left-sided" scheme="light" contains-html>
                        ${
							this.hasAttribute("second-title")
								? `
                                <b class="regular f-light">
                                    ${this.getAttribute("second-title")}
                                </b>
                                <br>
                                `
								: ""
						}
                        ${this.getAttribute("second-paragraph")} 
                        </text-comp>
                    </div>
                    <div class="sliders__item ${
						this.hasAttribute("dualSlider") ? "hidden" : ""
					}">
                        <text-comp class="item" variant="slider no-margin left-sided" scheme="light" contains-html>
                        ${
							this.hasAttribute("third-title")
								? `
                                <b class="regular f-light">
                                    ${this.getAttribute("third-title")}
                                </b>
                                <br>
                                `
								: ""
						}
                        ${this.getAttribute("third-paragraph")}
                        </text-comp>
                    </div>
                </div>
            </div>
            <div class="item__controlls">
            <img class="left-arrow" src="${leftArrow}" position="previous">
            <img class="right-arrow" src="${rightArrow}" position="next">
            </div>
        </div>
        `;
		this.addListeners();
	}
}
customElements.define("text-slider", TextSliderComponent);
