import "./router";

//? Pages
import "./pages/home";
import "./pages/companies/indalo-media";
import "./pages/companies/oil";
import "./pages/companies/cpc";
import "./pages/companies/providencia";
import "./pages/companies/alpat";
import "./pages/companies/serma";
import "./pages/companies/pilgrim";

//? Components
import "./components/header";
import "./components/footer";
import "./components/company-header";
import "./components/menu";
import "./components/hero-slider";
import "./components/title";
import "./components/subtitle";
import "./components/text";
import "./components/text-slider";
import "./components/link";
import "./components/accordion-item";
import "./components/about-content";
import "./components/companies-content";
import "./components/company-card";
import "./components/companies-slider";
import "./components/collabs-slider";
import "./components/video-embed";
import "./components/companies-content";
import "./components/translate-button";

// english
import "./pages/en/home";
import "./pages/en/companies/indalo-media";
import "./pages/en/companies/oil";
import "./pages/en/companies/cpc";
import "./pages/en/companies/providencia";
import "./pages/en/companies/alpat";
import "./pages/en/companies/serma";
import "./pages/en/companies/pilgrim";

//? Components
import "./components/en/header";
import "./components/en/footer";
import "./components/en/company-header";
import "./components/en/menu";
import "./components/en/hero-slider";
// import "./components/en/title";
// import "./components/en/subtitle";
// import "./components/en/text";
// import "./components/en/text-slider";
// import "./components/en/link";
import "./components/en/accordion-item";
import "./components/en/about-content";
import "./components/en/company-card";
import "./components/en/companies-slider";
// import "./components/en/collabs-slider";
// import "./components/en/video-embed";
import "./components/en/companies-content";