const en_menuLogo = require("url:../../../assets/indalo-logo.svg");
const en_instagramLogo = require("url:../../../assets/icons/instagram.svg");
const en_linkedInLogo = require("url:../../../assets/icons/linkedin.svg");
const en_twitterLogo = require("url:../../../assets/icons/twitter.svg");

class en_menuComponent extends HTMLElement {
    shadow = this.attachShadow({ mode: "open" });
    constructor() {
        super();

        let style = document.createElement("style");
        style.textContent = `

        .menu-container {
            width: calc(100% - 2px);
            height: 90vh;
            display: none;
            background-color: var(--menu-bkgd-color);
            backdrop-filter: blur(32px);
            -webkit-backdrop-filter: blur(32px);
            border: 1px solid var(--white-color);
        }
        
        .menu-container.active {
            z-index: 199;
            left: 0;
            right: 0;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            animation: forwardExpand 400ms ease forwards;
        }

        .menu__nav-links {
            width: 100%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 10vh;
            gap: 0px;
        }

        @media (min-width: 769px) {
            .menu__nav-links {
                height: 50vh;
                padding-top: 0;
                justify-content: space-evenly;
            }
        }

        .nav-link {
            padding: 25px;
            border-bottom: 1px solid var(--white-color);
        }

        .nav-link.companies-list {
            padding-bottom: 15px;
        }

        .menu__social-media {
            width: 50%;
            padding-top: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 25px;
        }

        @media (min-height: 840px) {
            .menu__social-media {
                padding-top: 60px;
            }
        }

        @media (min-width: 1280px) {
            .menu__social-media {
                width: 200px;
                justify-content: flex-start;
            }
        }

        .menu__social-media-logo {
            width: 50px;
        }

        .social-media__link {
            text-decoration: none;
        }

        @keyframes forwardExpand {
            0% {
                opacity: 0;
                transform: translateY(-100%);
            }
        
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }

        @keyframes backwardExpand {
            0% {
                opacity: 0;
                transform: translateY(0);
            }
        
            100% {
                opacity: 1;
                transform: translateY(-100%);
            }
        }
        `;

        this.render();
        this.shadow.appendChild(style);
        this.addListeners();
    }

    render() {
        this.shadow.innerHTML = `
            <div class="menu-container">
                <div class="menu__nav-links">
                    <link-comp class="nav-link" variant="menu" scheme="light" href="/en#hero">Home</link-comp>
                    <link-comp class="nav-link" variant="menu" scheme="light" href="/en#about-us">About us</link-comp>
                    <en-accordion-item class="nav-link companies-list">Companies</en-accordion-item>
                    <link-comp class="nav-link" variant="menu" scheme="light" href="/en#sust">Sustainability</link-comp>
                </div>
                <div class="menu__social-media">
                    <a class="social-media__link" href="" target="_blank">
                        <img class="menu__social-media-logo" src="${en_linkedInLogo}" alt="LinkedIn">
                    </a>
                    <a class="social-media__link" href="" target="_blank">
                    <img class="menu__social-media-logo" src="${en_twitterLogo}" alt="Twitter">
                    </a>
                    <a class="social-media__link" href="https://www.instagram.com/molde__arq/" target="_blank">
                    <img class="menu__social-media-logo" src="${en_instagramLogo}" alt="Instagram">
                    </a>
                </div>
            </div>
        `;
    }

    addListeners() {
        const burgerMenuEl: HTMLElement =
            this.shadow.querySelector(".menu-container");

        const scrollCheckEvent = () => {
            const scrollTop = document.documentElement.scrollTop;
            if (
                scrollTop > window.innerHeight * 2.7 &&
                location.pathname === "/"
            ) {
                burgerMenuEl.style.backgroundColor = "var(--primary-color)";
            } else if (scrollTop > window.innerHeight * 0.7) {
                burgerMenuEl.style.backgroundColor = "var(--primary-color)";
            } else {
                burgerMenuEl.style.backgroundColor = "var(--menu-bkgd-color)";
            }
        };

        window.addEventListener("scroll", scrollCheckEvent);
    }
}
customElements.define("en_menu-comp", en_menuComponent);
