class TextComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
        
        .section {
            line-height: 22px;
            margin-top: 15px;
            width: calc(100vw - 120px);
            font: 400 13px var(--montserrat-font);
        }

        @media (min-width: 960px) {
            .section {
                font-size: 36px;
            }
        }

        @media (min-width: 1440px) {
            .section {
                font-size: 75px;
            }
        }

        .hero {
            font: 400 19px var(--jakarta-font);
        }

        .hero img {
            vertical-align: middle;
        }

        @media (min-width: 630px) {            
            .about {
                margin: 10px;
                line-height: 20px;
                font: 400 15px var(--jakarta-font);
            }
        }

        @media (min-width: 960px) {            
            .about {
                font-size: 17px;
            }
        }

        @media (min-width: 960px) and (max-height: 720px) {            
            .about {
                font-size: 17px;
            }
        }

        @media (min-width: 1280px) {            
            .about {
                font-size: 20px;
            }
        }

        @media (min-width: 1440px) {            
            .about { 
                font-size: 23px;
            }
        }

        @media (min-width: 1850px) {            
            .about {
                font-size: 30px;
                line-height: 45px;
            }
        }

        @media (min-width: 630px) {
            .about.company-hero {
                line-height: 20px;
            }
        }

        @media (min-width: 768px) {
            .about.company-hero {
                line-height: 24px;
            }
        }

        @media (min-width: 960px) {
            .about.company-hero {
                width: 100%;
                line-height: 33px;
            }
        }

        @media (min-width: 960px) and (max-height: 720px) {
            .about.company-hero {
                line-height: 25px;
            }
        }

        @media (min-width: 1280px) {
            .about.company-hero {
                font-size: 20px;
                line-height: 33px;
            }
        }

        @media (min-width: 1440px) {
            .about.company-hero {
                font-size: 23px;
                line-height: 37px;
            }
        }

        @media (min-width: 1850px) {
            .about.company-hero {
                font-size: 25px;
                line-height: 45px;
            
            }
        }

        .slider {
            font: 500 13px var(--montserrat-font);
        }

        @media (min-width: 500px) {
            .slider {
                font-size: 16px;
            }
        }

        @media (min-width: 960px) {
            .slider {
                font-size: 18px;
            }
        }

        @media (min-width: 1280px) {
            .slider {
                line-height: 30px;
                font-size: 20px;
            }
        }

        @media (min-width: 1440px) {
            .slider {
                font-size: 22px;
            }
        }

        @media (min-width: 1850px) {
            .slider {
                line-height: 38px;
                font-size: 24px;
            }
        }

        .card {
            font: 500 13px var(--jakarta-font);
        }

        @media (min-width: 1440px) {
            .card {
                font-size: 16px;
            }
        }

        @media (min-width: 1850px) {
            .card {
                font-size: 20px;
            }
        }

        .card.border {
            border-bottom: 1px solid var(--secondary-color);
        }

        .footer {
            font: 500 20px var(--aeonik-font);
        }

        @media (min-width: 768px) {
            .footer {
                font-family: var(--jakarta-font);
                font-size: 24px;
            }
        }

        @media (min-width: 960px) {
            .footer {
                font-size: 32px;
            }
        }

        @media (min-width: 1280px) {
            .footer {
                font-size: 40px;
            }
        }

        @media (min-width: 1440px) {
            .footer {
                font-size: 50px;
            }
        }

        @media (min-width: 1850px) {
            .footer {
                font-size: 64px;
            }
        }
        
        .footer-details {
            font: 400 8px var(--roboto-font);
        }

        @media (min-width: 768px) {
            .footer-details {
                margin: 20px 0;
                font-family: var(--jakarta-font);
                font-size: 12px;
                border-bottom: 1px solid var(--white-color);
            }
        }

        @media (min-width: 960px) {
            .footer-details {
                margin: 0;
                margin-bottom: 25px;
                font-size: 16px;
            }
        }

        @media (min-width: 1440px) {
            .footer-details {
                margin-bottom: 35px;
                font-size: 19px;
            }
        }

        .footer-details a {
            color: var(--secondary-color);
        }

        .footer-details.copyright {
            margin-bottom: -13px;
        }

        @media (min-width: 768px) {
            .footer-details.copyright {
                display: none;
            }
        }

        .dark {
            color: var(--primary-color);
        }

        .light {
            color: var(--text-color);
        }

        .f-light {
            color: var(--white-color);
        }

        .regular {
            font-weight: 400;
        }

        .no-margin {
            margin: 0;
        }

        .margin-top {
            margin-top: 10px;
        }

        @media (min-width: 1850px) {
            .margin-top {
                margin-top: 35px;
            }    
        }

        .left-sided {
            text-align: left;
        }

        .middle-sided {
            text-align: center;
        }

        .right-sided {
            text-align: right;
        }
        `;

		this.render();
		this.shadow.appendChild(style);
	}

	render() {
		this.shadow.innerHTML = `
            <p class="
            ${this.getAttribute("variant")}
            ${this.getAttribute("scheme")}
            ">
            ${
				this.hasAttribute("contains-html")
					? this.innerHTML
					: this.textContent
			}
            </p>
        `;
	}
}
customElements.define("text-comp", TextComponent);
