import { Router } from "@vaadin/router";

const en_toggleArrow = require("url:../../../assets/icons/toggle-arrow.svg");

class en_CardComponent extends HTMLElement {
	shadow = this.attachShadow({ mode: "open" });
	constructor() {
		super();

		let style = document.createElement("style");
		style.textContent = `
                    .company-card {
                        width: 210px;
                        height: 360px;
                        position: relative;
                        left: 0;
                        right: 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        align-items: center;
                        background-color: var(--black-color);
                        border: none;
                        border-radius: 10px; 
                        transition: all .4s;
                    }

                    @media (min-width: 1440px) {
                        .company-card {
                            width: 277px;
                            height: 480px;
                        }
                    }
  
                    @media (min-width: 1850px) {
                        .company-card {
                            width: 345px;
                            height: 600px;
                        }
                    }

                    .company-card:hover {
                        background-color: var(--secondary-color);   
                        cursor: pointer;
                    }

                    .company-card__wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                    }
                    
                    .company-card__data {
                        width: calc(100% - 40px);
                        height: 100%;
                        padding: 0 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
            
                    @media (min-width: 768px) {
                        .company-card__data {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                        }
                    }
            
                    .company-card__logo-container {
                        width: 100%;
                        height: 30%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    @media (min-width: 1850px) {
                        .company-card__logo-container {
                            width: 85%;
                        }
                    }

                    .company-card__logo {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 80px;
                    }

                    @media (min-width: 960px) {
                        .company-card__logo {
                            max-height: 75px;
                        }
                    }

                    @media (min-width: 1850px) {
                        .company-card__logo {
                            max-height: 100px;
                        }
                    }

                    .company-card__logo.rectangular-logos {
                        width: 100%;
                    }

                    @media (min-width: 1440px) {
                        .company-card__logo.rectangular-logos {
                            width: 75%;
                        }    
                    }

                    @media (min-width: 1850px) {
                        .company-card__logo.rectangular-logos {
                            width: 90%;
                        }    
                    }

                    .company-card__logo.square-logos {
                        height: 100%;
                    }
            
                    .company-card__redirect {
                        width: 100%;
                        height: 50px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        background-color: var(--gray-color);
                        border-radius: 0 0 9px 9px;
                        transition: all .4s;
                    }

                    @media (min-width: 1850px) {
                        .company-card__redirect {
                            height: 85px;
                        }
                    }

                    .company-card__redirect:hover {
                        opacity: .8;
                    }

                    .company-card__redirect-arrow {
                        width: 29px;
                    }

                    .company-card__redirect-arrow:hover {
                        cursor: pointer;
                    }

                    .company-card__link {
                        width: 100%;
                        height: 100%;
                        padding: 0 20px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 5px;
                        text-decoration: none;
                    }

                    .company-card__link:hover {
                        cursor: pointer;
                    }
            
                    `;

		this.render();
		this.shadow.appendChild(style);
		this.addListeners();
	}

	render() {
		this.shadow.innerHTML = `
                    <div class="company-card">
                        <div class="company-card__wrapper">
                            <div class="company-card__data">
                                <div class="company-card__logo-container">
                                    <img class="company-card__logo ${
										this.hasAttribute("rectangularLogo")
											? "rectangular-logos"
											: ""
									}
                                    ${
										this.hasAttribute("squareLogo")
											? "square-logos"
											: ""
									}
                                    "
                                    src="${this.getAttribute("src")}">
                                </div>

                                <text-comp variant="card middle-sided" scheme="f-light">
                                ${this.textContent}
                                </text-comp>
                            </div>
                            <div class="company-card__redirect">
                                <a class="company-card__link" path="${this.getAttribute(
									"path"
								)}">
                                    <img class="company-card__redirect-arrow" src="${en_toggleArrow}" alt="">
                                    <text-comp variant="card" scheme="dark">See more</text-comp>
                                </a>
                            </div>
                        </div>
                    </div>
                    `;
	}

	addListeners() {
		const companyCardEl = this.shadow.querySelector(".company-card");

		const companyCardLinkEl = this.shadow.querySelector(
			".company-card__link"
		);

		function getPageMaxScroll() {
			return (
				Math.max(
					document.body.scrollHeight,
					document.body.offsetHeight,
					document.documentElement.clientHeight,
					document.documentElement.scrollHeight,
					document.documentElement.offsetHeight
				) - window.innerHeight
			);
		}

		const scrollUp = () => {
			let top = 1000000;
			const maxScroll = getPageMaxScroll();

			if (top > maxScroll) {
				top = maxScroll;
			}
			const rect = document.querySelector("div").getBoundingClientRect();

			window.scrollTo({
				top: rect.top,
				behavior: "smooth",
			});
		};
		scrollUp();

		companyCardEl.addEventListener("click", () => {
			const path = this.getAttribute("path");
			Router.go(path);
			setTimeout(scrollUp, 100);
		});

		companyCardLinkEl.addEventListener("click", () => {
			const path = this.getAttribute("path");
			Router.go(path);
			setTimeout(scrollUp, 100);
		});
	}
}
customElements.define("en-company-card", en_CardComponent);
